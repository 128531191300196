import { FC } from 'react'
import classnames from 'classnames'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import IWelcomeBanner from './WelcomeBanner.types'

import './WelcomeBanner.scss'

const WelcomeBanner: FC<IWelcomeBanner> = (props) => {
  const { id, className, name, email, company } = props
  const { t } = useTranslation()
  return (
    <div
      id={id}
      className={classnames('welcome-banner', className)}
      data-test="welcome-banner-component"
    >
      <Typography
        className="welcome-banner__text"
        data-test="user-name"
        variant="h1"
        dangerouslySetInnerHTML={{
          __html: `${t('overview.welcomeMessage')}, ${name}!`,
        }}
        role="heading"
        aria-level={1}
      />
      <Typography variant="body2" data-test="user-email">
        <span
          dangerouslySetInnerHTML={{
            __html: email,
          }}
        />{' '}
        {company && (
          <span data-test="user-company">
            |{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: company,
              }}
            />
          </span>
        )}
      </Typography>
    </div>
  )
}

export default WelcomeBanner
