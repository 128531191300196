import { FC } from 'react'
import classnames from 'classnames'
import Typography from '@mui/material/Typography'

import IInvoiceNoDataMessage from './InvoiceNoDataMessage.types'

import NoDataImage from '../../../../../assets/images/no-data-icon.svg'

import './InvoiceNoDataMessage.scss'

const InvoiceNoDataMessage: FC<IInvoiceNoDataMessage> = (props) => {
  const { id, className, message, description } = props

  return (
    <div
      id={id}
      className={classnames('invoice-no-data-message', className)}
      data-test="invoice-no-data-message-component"
    >
      <img src={NoDataImage} alt="no-data" data-test="invoice-no-data-message-icon" />
      <Typography
        variant="h5"
        className="invoice-no-data-message__text mt_10"
        data-test="invoice-no-data-message-text"
      >
        {message}
      </Typography>
      {description && (
        <Typography
          variant="body2"
          className="invoice-no-data-message__description mt_5"
          data-test="invoice-no-data-message-description"
        >
          {description}
        </Typography>
      )}
    </div>
  )
}

export default InvoiceNoDataMessage
