import { FC } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

import PaymentModal from '../paymentModal/PaymentModal'
import { RouteHelper } from '../../../../../helpers/RouteHelper'
import IQuickLinks from './QuickLinks.types'
import { replaceParameters } from '../../../../../utility/CommonUtility'

import './QuickLinks.scss'

const QuickLinks: FC<IQuickLinks> = (props) => {
  const {
    id,
    className,
    openPaymentModal,
    teamId,
    isHideOtherLinks,
    isContractLock,
    setOpenPaymentModal,
  } = props
  const { t } = useTranslation()
  const invoiceManagerPath = replaceParameters(RouteHelper.PATH.INVOICE_MANAGER, { teamId })
  const planManagerPath = replaceParameters(RouteHelper.PATH.PLAN_MANAGER_CUSTOMER_SECTION, {
    teamId,
  })

  return (
    <div id={id} className={classnames('quick-links', className)} data-test="quick-links-component">
      <Typography variant="body2" data-test="invoice-link">
        <Link id="link-invoice-manager" to={invoiceManagerPath} className="quick-links__links">
          {t('overview.links.invoiceHistory')}
        </Link>
      </Typography>
      {!isHideOtherLinks && (
        <Typography
          variant="body2"
          data-test="payment-link"
          className="payment-link"
          onClick={() => setOpenPaymentModal?.(true)}
        >
          {isContractLock && <ReportProblemIcon className="payment__error-icon" />}
          <span
            id="link-payment-modal"
            className="clickable quick-links__links"
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter') setOpenPaymentModal?.(true)
            }}
          >
            {t('overview.links.updatePayment')}
          </span>
        </Typography>
      )}
      <Typography variant="body2" data-test="change-invoice-link">
        <Link id="link-plan-manager" to={planManagerPath} className="quick-links__links">
          {t('overview.links.changeCustomerDetails')}
        </Link>
      </Typography>

      {openPaymentModal && (
        <PaymentModal id={'payment-modal'} open={openPaymentModal} onClose={setOpenPaymentModal} />
      )}
    </div>
  )
}

export default QuickLinks
