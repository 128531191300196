import { Routes, Route } from 'react-router-dom'

import Overview from '../features/store/containers/overview/Overview'
import PlanManager from '../features/store/containers/planManager/PlanManager'
import InvoiceManager from '../features/store/containers/invoiceManager/InvoiceManager'
import ComingSoonMessage from '../features/shared/components/comingSoonMessage/ComingSoonMessage'
import ErrorMessage from '../features/shared/components/errorMessage/ErrorMessage'
import { RouteHelper } from '../helpers/RouteHelper'
import { getMetaValueByTagName, checkActionAvailability } from '../utility/CommonUtility'

const Routers = () => {
  const routeAvailability = checkActionAvailability(
    process.env.REACT_APP_ENVIRONMENT || getMetaValueByTagName('REACT_APP_ENVIRONMENT')
  )
  return (
    <Routes>
      <Route path="/" element={<ComingSoonMessage />} />
      {routeAvailability && (
        <>
          <Route path={RouteHelper.BASE.OVERVIEW} element={<Overview />} />
          <Route path={RouteHelper.BASE.PLAN_MANAGER} element={<PlanManager />} />
        </>
      )}
      <Route path={RouteHelper.BASE.INVOICE_MANAGER} element={<InvoiceManager />} />
      <Route path="*" element={<ErrorMessage />} />
    </Routes>
  )
}

export default Routers
