export const StoreHelper = {
  PLAN_LIST: [
    {
      id: 1,
      title: 'Premium',
      price: 6.45,
      isActive: true,
      activeLabel: 'Active',
      description: 'Per user, per month, billed semi-annually (min. 2 users)',
      subDescription: '<b>12 Seats in use</b>',
      buttonText: 'Manage plan',
      currencyVisibility: true,
      componentId: '2',
    },
    {
      id: 2,
      title: 'Business',
      price: 9,
      description: 'Per user, per month, billed annually (min. 10 users)',
      subDescription: 'For larger businesses and those with additional administrative needs.',
      buttonText: 'Select plan',
      currencyVisibility: true,
      componentId: '3',
    },
    {
      id: 3,
      title: 'Enterprise',
      price: 'Price on request',
      description: '250 minimum annual licenses...',
      subDescription: 'For enterprises and institutions that need custom solutions.',
      buttonText: 'Contact us',
      currencyVisibility: false,
      componentId: '4',
    },
  ],
  PAYMENT_AND_INVOICE_SUMMARY: {
    cardNumber: '4532',
    nextBillingDate: '2022-08-29',
  },
  PLAN_MANAGER_BREADCRUMB_LIST: [
    {
      id: 1,
      title: 'Conceptboard Store',
      link: '/overview',
      color: 'inherit',
      underline: 'hover',
    },
    {
      id: 3,
      title: 'Manage your plan',
    },
  ],
  SELECTABLE_PLAN_LIST: [
    {
      id: '1',
      title: 'Premium',
      price: 6,
      isActive: true,
      description:
        'Per user, per month with annual billing <span class="plan-info__min-count">(min. 2 users)<span>',
      componentId: '2',
    },
    {
      id: '2',
      title: 'Business',
      price: 9,
      description:
        'Per user, per month with annual billing <span class="plan-info__min-count">(min. 10 users)<span>',
      componentId: '3',
    },
  ],
  BILLING_PERIOD_LIST: [
    [
      {
        value: 'M1',
        label: 'Monthly',
      },
      {
        value: 'M3',
        label: 'Quarterly',
      },
      {
        value: 'M6',
        label: 'Semi-annually',
      },
      {
        value: 'M12',
        label: 'Annually',
      },
    ],
  ],
  COUNTRY_LIST: [
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Andorra', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cape Verde', value: 'CV' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Congo, The Democratic Republic of the Congo', value: 'CD' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: "Cote D'Ivoire", value: 'CI' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
    { label: 'Holy See (Vatican City State)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran, Islamic Republic Of Iran', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: "Korea, Democratic People'S Republic of Korea", value: 'KP' },
    { label: 'Korea, Republic of Korea', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: "Lao People'S Democratic Republic", value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Macedonia, The Former Yugoslav Republic of Macedonia', value: 'MK' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia, Federated States of Micronesia', value: 'FM' },
    { label: 'Moldova, Republic of Moldova', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Netherlands Antilles', value: 'AN' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestinian Territory, Occupied', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Reunion', value: 'RE' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation', value: 'RU' },
    { label: 'RWANDA', value: 'RW' },
    { label: 'Saint Helena', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia and Montenegro', value: 'CS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Surilabel', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of Tanzania', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States', value: 'US' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands, British', value: 'VG' },
    { label: 'Virgin Islands, U.S.', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' },
  ],
  INVOICE_LANGUAGE_LIST: [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'de',
      label: 'German',
    },
  ],
  VAT_PRECENTAGE: 19,
  INVOICE_MANAGER_BREADCRUMB_LIST: [
    {
      id: 1,
      title: 'Conceptboard Store',
      link: '/',
      color: 'inherit',
      underline: 'hover',
    },
    {
      id: 2,
      title: 'Invoice history',
    },
  ],
  SAMPLE_INVOICE_LIST: [
    { id: 1, issuedDate: '2022-10-24', referenceNumber: 'ABC456433SSC', totalAmount: 1440.0 },
    { id: 2, issuedDate: '2022-09-21', referenceNumber: 'ABC456433SSY', totalAmount: 1640.0 },
    { id: 3, issuedDate: '2022-08-02', referenceNumber: 'ABC456433SSE', totalAmount: 470.0 },
    { id: 4, issuedDate: '2022-07-21', referenceNumber: 'ABC456433S9Y', totalAmount: 860.0 },
    { id: 5, issuedDate: '2022-06-02', referenceNumber: 'ABC456433SS8', totalAmount: 645.9 },
    { id: 6, issuedDate: '2022-05-21', referenceNumber: 'ABC456433SS9', totalAmount: 800.0 },
    { id: 7, issuedDate: '2022-04-02', referenceNumber: 'ABC456433SS3', totalAmount: 2300.0 },
    { id: 8, issuedDate: '2022-03-21', referenceNumber: 'ABC456433SS2', totalAmount: 1245.0 },
    { id: 9, issuedDate: '2022-02-02', referenceNumber: 'ABC456433SS1', totalAmount: 2111.0 },
    { id: 10, issuedDate: '2022-01-21', referenceNumber: 'ABC456433SS4', totalAmount: 2200.0 },
  ],
  SAMPLE_INVOICE_INFO: {
    id: '63628340954931eac4729d8d',
    planId: '1',
    planTitle: 'Premium',
    numberOfSeats: '4',
    billingPeriod: '1',
    firstName: 'John',
    lastName: 'Wick',
    companyName: 'Dream Team',
    addressLineOne: '',
    addressLineTwo: '',
    street: 'Mansfelder Str',
    streetNumber: '56',
    cityNumber: '06108',
    city: 'Halle',
    country: 'DE',
    euVatId: '',
    invoiceEmail: ['john.wick@conceptboard.com'],
    invoiceLanguage: 'en',
  },
  LEARN_MORE_LINK: {
    en: 'https://conceptboard.com/contact-request/',
    de: 'https://conceptboard.com/de/kontaktanfrage/',
  },
  INVOICE_LIST_PAGE_LIMIT: 20,
  SAMPLE_CHECKOUT_RESPONSE: {
    customer: {
      externalCustomerId: '68c219e2-6af3-47c2-8209-b727b8f2dccc',
      debitorAccount: '10052',
      companyName: 'Rodrigo Company',
      firstName: 'Test Changes in Seats',
      lastName: 'Rodrigo',
      vatId: '',
      emailAddress: 'test@g.com',
      additionalEmailAddresses: [
        {
          emailAddress: 'test1@g.com',
        },
        {
          emailAddress: 'test2@g.com',
        },
      ],
      address: {
        addressLine1: 'Test Rd',
        addressLine2: 'Test',
        street: '12',
        houseNumber: '12',
        postalCode: '145',
        city: 'Test',
        country: 'DE',
      },
      locale: 'de',
      customFields: {},
      defaultBearerMedium: 'ArchiveOnly',
      customerType: 'Consumer',
      hidden: false,
      id: '63691261b003fba439f63d86',
      createdAt: '2022-11-07T14:12:49',
      isDeletable: false,
      isLocked: false,
      customerName: 'Rodrigo Company',
      customerSubName: 'Rodrigo, Test Changes in Seats',
    },
    contract: {
      id: '637ce96b0a48fb33893acbb1',
      lastBillingDate: '2022-11-22T23:00:00',
      nextBillingDate: '2022-11-29T23:00:00',
      planId: '6357f183a003bb98fbf25cad',
      customerId: '63691261b003fba439f63d86',
      isDeletable: false,
      lifecycleStatus: 'Active',
      trialEndDate: '2022-11-23T23:00:00',
      customerName: 'Rodrigo Company',
      customerIsLocked: false,
      phases: [
        {
          type: 'Trial',
          startDate: '2022-11-21T23:00:00',
          planVariantId: '6357f201d25c517223e97122',
          planId: '6294e90ad2044479fcfd5158',
          quantity: 1,
          inheritStartDate: false,
        },
        {
          type: 'Inactive',
          startDate: '2022-11-23T23:00:00',
          inheritStartDate: false,
        },
      ],
      pauses: [],
      balance: 0,
      discount: 0,
      referenceCode: 'XCSYR-SDBSG',
      currency: 'EUR',
      planGroupId: '6357f159f14077d46244578c',
      paymentProvider: 'InvoicePayment',
      paymentProviderRole: 'BlackLabel',
      initialPaymentProviderRole: '',
      escalationSuspended: false,
      recurringPaymentsPaused: false,
      currentPhase: {
        type: 'Trail', //['Trail]
        startDate: '2022-11-21T23:00:00',
        planVariantId: '6357f201d25c517223e97122',
        planId: '6294e90ad2044479fcfd5158',
        quantity: 5,
        inheritStartDate: false,
      },
      paymentProviderSupportRefunds: false,
      billingSuspended: false,
      thresholdBillingDisabled: false,
      salesEntityId: '6294e633e636b694d8fe7a5e',
      startDate: '2022-11-21T23:00:00',
      endDate: '2022-11-23T23:00:00',
      planVariantId: '6357f201d25c517223e97122',
      customFields: {},
      paymentBearer: {
        CardType: 'Visa',
        expiryMonth: 11,
        expiryYear: 2022,
        Holder: 'Marcellus Wallace',
        last4: '1234',
        Type: 'CreditCard',
        Country: 'DE',
      },
      billingPeriod: {
        quantity: 12,
        unit: 'Month',
      },
    },
    currentPlanVariant: {
      id: '6294ee18471b3f8ab4ae5292',
      planId: '6294e90ad2044479fcfd5158',
      endAfterFirstContractPeriod: false,
      allowSelfService: true,
      allowWithoutPaymentData: true,
      contractPeriod: {
        quantity: 12,
        unit: 'Month',
      },
      laterContractPeriod: {
        quantity: 12,
        unit: 'Month',
      },
      cancellationPeriod: {
        quantity: 1,
        unit: 'Month',
      },
      laterCancellationPeriod: {
        quantity: 1,
        unit: 'Month',
      },
      billingPeriod: {
        quantity: 6,
        unit: 'Month',
      },
      feePeriod: {
        quantity: 1,
        unit: 'Month',
      },
      planGroupId: '6294e8bf471b3f8ab4ae46e1',
      paymentPeriodMode: 'PrePaid',
      quota: [],
      recurringFee: 0.0,
      setupFee: 0.0,
      internalName: 'Premium Annual',
      description: {},
      billingDateAlignment: 'None',
      externalId: 'PUB_EUR_PRE_12M',
      hidden: false,
      afterFirstContractPeriodNextStep: 'RenewAutomatically',
    },
    currentPlan: {
      id: '6294e90ad2044479fcfd5158',
      name: {
        _c: 'Premium',
      },
      setupDescription: {
        _c: 'Setup',
      },
      planDescription: {
        _c: 'Public Cloud Premium License',
      },
      trialPeriod: {
        quantity: 30,
        unit: 'Day',
      },
      trialEndNotificationPeriod: {
        quantity: 7,
        unit: 'Day',
      },
      trialEndPolicy: 'DeactivateAccount',
      requiresOrderApproval: false,
      customFields: {},
      planGroupId: '6294e8bf471b3f8ab4ae46e1',
    },
    currentComponentSubscriptions: [
      {
        billedUntil: '2023-01-10T14:24:38.934Z',
        componentId: '629a25c62c6833903dc66c73',
        contractId: '63c572d4e86d7f09abcb6ad7',
        customerId: '63983b8cea47b985e440a173',
        id: '63c572d4e86d7f09abcb6ad8',
        endDate: '2023-01-10T14:24:38.934Z',
        freeSeats: false,
        memo: 'string',
        message: 'string',
        productOverride: {
          description: 'string',
          message: 'string',
          name: 'string',
          pricePerUnit: 0,
        },
        quantity: 10,
        startDate: '2023-01-10T14:24:38.934Z',
      },
      {
        billedUntil: '2023-01-10T14:24:38.934Z',
        componentId: '629a25c62c6833903dc66c73',
        contractId: '63c572d4e86d7f09abcb6ad7',
        customerId: '63983b8cea47b985e440a173',
        id: '63c572d4e86d7f09abcb6ad81',
        endDate: '2023-01-10T14:24:38.934Z',
        freeSeats: true,
        memo: 'string',
        message: 'string',
        productOverride: {
          description: 'string',
          message: 'string',
          name: 'string',
          pricePerUnit: 0,
        },
        quantity: 4,
        startDate: '2023-01-10T14:24:38.934Z',
      },
    ],
    futureComponentSubscription: [
      {
        id: '64252468e02d6b37611e4ef7',
        contractId: '64252441e02d6b37611e4eb5',
        customerId: '63983b8cea47b985e440a173',
        componentId: '6367ea64853a4ec6bab77474',
        quantity: 5.0,
        startDate: '2023-04-28T22:00:00',
        freeSeats: true,
      },
      {
        id: '64252468e02d6b37611e4ef6',
        contractId: '64252441e02d6b37611e4eb5',
        customerId: '63983b8cea47b985e440a173',
        componentId: '6294eb1bd2044479fcfd5765',
        quantity: 13.0,
        startDate: '2023-04-28T22:00:00',
        freeSeats: false,
      },
    ],
    customerMessage:
      'Your Premium License with 5 seats is configured and will be billed Monthly starting on Apr 29, 2023, 12:00:00 AM',
    confirmationInfo: {
      changeDate: '2023-01-02T17:07:52.150Z',
      items: [
        {
          description: 'Premium Annual',
          pricePerUnit: 5.15,
          quantity: 20,
          scaleAmount: 20,
          totalGross: 1305.43,
          totalNet: 1097,
          vatPercentage: 19,
        },
        {
          description: 'Business Annual',
          pricePerUnit: 8,
          quantity: 10,
          scaleAmount: 10,
          totalGross: -1044.31,
          totalNet: -877.6,
          vatPercentage: 19,
        },
      ],
      nextInvoiceDate: '2023-12-02T17:07:52.150Z',
      nextInvoiceTotal: 1428,
      orderId: '5654645644232f43',

      totalGross: 261.09,
      total: 219.4,
      totalVat: 41.69,
      trialEnds: '2023-02-19T17:07:52.150Z',
    },
  },
  TOKEN_SEAT_COUNT: 1,
  MAX_SEAT_LIMIT: 10,
  MIN_SEAT_LIMIT: 2,
  CONTRACT_STATUS: {
    TRIAL: 'InTrial',
    ACTIVE: 'Active',
    INACTIVE: 'In active',
    NORMAL: 'Normal',
  },
  PLAN_TYPES: {
    PREMIUM: 'Premium',
    BUSINESS: 'Business',
    ENTERPRISE: 'Enterprise',
    EDUCATION: 'Education',
  },
  ENTERPRISE_USER_LIMIT: 250,
  SAMPLE_ACTIVE_ENTERPRISE: {
    customer: {
      externalCustomerId: 'ca9fa1aa-4e59-4b1c-b1eb-848a5074f399@integration',
      companyName: 'TOKEN',
      firstName: 'Kanchbn',
      lastName: 'Bangar2',
      vatId: 'PL0123456789',
      emailAddress: 'davortest1@gmail.com',
      additionalEmailAddresses: [
        {
          emailAddress: 'kcchan@gmail.com',
        },
      ],
      address: {
        addressLine1: 'Dammweg 1',
        addressLine2: '1246',
        street: 'Dammwg',
        houseNumber: '212',
        postalCode: '12557',
        city: 'Berli',
        country: 'PT',
      },
      locale: 'de',
      customFields: {},
      defaultBearerMedium: 'Email',
      customerType: 'Consumer',
      vatIdValidationStatus: 'Invalid',
      lastValidationDate: '2023-02-15T09:11:32',
      hidden: false,
      id: '63983b8cea47b985e440a173',
      createdAt: '2022-12-13T08:45:00',
      isDeletable: false,
      isLocked: false,
      customerName: 'TOKEN',
      customerSubName: 'Bangar2, Kanchbn',
    },
    contract: {
      id: '63f70baf110453906b34b006',
      lastBillingDate: '2023-02-22T23:00:00',
      nextBillingDate: '2026-02-22T23:00:00',
      planId: '6297efca471b3f8ab4b4f640',
      customerId: '63983b8cea47b985e440a173',
      isDeletable: false,
      lifecycleStatus: 'Active',
      customerName: 'TOKEN',
      customerIsLocked: false,
      phases: [
        {
          type: 'Normal',
          startDate: '2023-02-22T23:00:00',
          planVariantId: '633ea3906e65bee3d365f088',
          planId: '6297efca471b3f8ab4b4f640',
          quantity: 1,
          inheritStartDate: false,
        },
        {
          type: 'Inactive',
          startDate: '2026-02-22T23:00:00',
          inheritStartDate: false,
        },
      ],
      pauses: [],
      balance: 7200,
      referenceCode: 'LZDRG-XBPRY',
      currency: 'EUR',
      planGroupId: '6294e8bf471b3f8ab4ae46e1',
      paymentProvider: 'InvoicePayment',
      paymentProviderRole: 'BlackLabel',
      initialPaymentProviderRole: '',
      escalationSuspended: false,
      recurringPaymentsPaused: false,
      currentPhase: {
        type: 'Normal',
        startDate: '2023-02-22T23:00:00',
        planVariantId: '633ea3906e65bee3d365f088',
        planId: '6297efca471b3f8ab4b4f640',
        quantity: 1,
        inheritStartDate: false,
      },
      paymentProviderSupportRefunds: false,
      billingSuspended: false,
      thresholdBillingDisabled: false,
      salesEntityId: '6294e633e636b694d8fe7a5e',
      startDate: '2023-02-22T23:00:00',
      endDate: '2026-02-22T23:00:00',
      billedUntil: '2026-02-22T23:00:00',
      planVariantId: '633ea3906e65bee3d365f088',
      customFields: {},
    },
    currentPlanVariant: {
      id: '633ea3906e65bee3d365f088',
      planId: '6297efca471b3f8ab4b4f640',
      endAfterFirstContractPeriod: true,
      allowSelfService: false,
      allowWithoutPaymentData: false,
      contractPeriod: {
        quantity: 36,
        unit: 'Month',
      },
      laterContractPeriod: {
        quantity: 1,
        unit: 'Month',
      },
      cancellationPeriod: {
        quantity: 1,
        unit: 'Month',
      },
      laterCancellationPeriod: {
        quantity: 1,
        unit: 'Month',
      },
      billingPeriod: {
        quantity: 36,
        unit: 'Month',
      },
      feePeriod: {
        quantity: 1,
        unit: 'Month',
      },
      planGroupId: '6294e8bf471b3f8ab4ae46e1',
      paymentPeriodMode: 'PrePaid',
      quota: [],
      nextPlanVariantId: '63211d01dd38ef5143b3efe9',
      nextPlanId: '63211d01dd38ef5143b3efe6',
      recurringFee: 0,
      setupFee: 0,
      internalName: 'Enterprise 3Y',
      description: {},
      billingDateAlignment: 'None',
      externalId: 'PUB_EUR_ENT_36M',
      hidden: false,
      afterFirstContractPeriodNextStep: 'EndAfterFirstPeriod',
    },
    currentPlan: {
      id: '6297efca471b3f8ab4b4f640',
      name: {
        _c: 'Enterprise',
      },
      setupDescription: {
        _c: 'Setup',
      },
      planDescription: {
        _c: 'Public Cloud Enterprise License',
      },
      trialEndPolicy: 'NoTrial',
      requiresOrderApproval: true,
      customFields: {},
      planGroupId: '6294e8bf471b3f8ab4ae46e1',
    },
    currentComponentSubscriptions: [
      {
        id: '63f70baf110453906b34b007',
        contractId: '63f70baf110453906b34b006',
        customerId: '63983b8cea47b985e440a173',
        componentId: '6297c78ce636b694d804e360',
        quantity: 20,
        startDate: '2023-02-22T23:00:00',
        billedUntil: '2026-02-22T23:00:00',
        freeSeats: false,
      },
    ],
  },
  SAMPLE_ACTIVE_EDUCATION: {
    customer: {
      externalCustomerId: 'ca9fa1aa-4e59-4b1c-b1eb-848a5074f399@integration',
      companyName: 'TOKEN',
      firstName: 'Kanchbn',
      lastName: 'Bangar2',
      vatId: 'PL0123456789',
      emailAddress: 'davortest1@gmail.com',
      additionalEmailAddresses: [
        {
          emailAddress: 'kcchan@gmail.com',
        },
      ],
      address: {
        addressLine1: 'Dammweg 1',
        addressLine2: '1246',
        street: 'Dammwg',
        houseNumber: '212',
        postalCode: '12557',
        city: 'Berli',
        country: 'PT',
      },
      locale: 'de',
      customFields: {},
      defaultBearerMedium: 'Email',
      customerType: 'Consumer',
      vatIdValidationStatus: 'Invalid',
      lastValidationDate: '2023-02-15T09:11:32',
      hidden: false,
      id: '63983b8cea47b985e440a173',
      createdAt: '2022-12-13T08:45:00',
      isDeletable: false,
      isLocked: false,
      customerName: 'TOKEN',
      customerSubName: 'Bangar2, Kanchbn',
    },
    contract: {
      id: '63f70c82ac6729558498fdb1',
      lastBillingDate: '2023-02-22T23:00:00',
      nextBillingDate: '2026-02-22T23:00:00',
      planId: '6297efa4471b3f8ab4b4f5cb',
      customerId: '63983b8cea47b985e440a173',
      isDeletable: false,
      lifecycleStatus: 'Active',
      customerName: 'TOKEN',
      customerIsLocked: false,
      phases: [
        {
          type: 'Normal',
          startDate: '2023-02-22T23:00:00',
          planVariantId: '6364f623b8639ea9da21f828',
          planId: '6297efa4471b3f8ab4b4f5cb',
          quantity: 1,
          inheritStartDate: false,
        },
        {
          type: 'Inactive',
          startDate: '2026-02-22T23:00:00',
          inheritStartDate: false,
        },
      ],
      pauses: [],
      balance: 0,
      referenceCode: 'ZBSCM-XXHKJ',
      currency: 'EUR',
      planGroupId: '6294e8bf471b3f8ab4ae46e1',
      paymentProvider: 'InvoicePayment',
      paymentProviderRole: 'BlackLabel',
      initialPaymentProviderRole: '',
      escalationSuspended: false,
      recurringPaymentsPaused: false,
      currentPhase: {
        type: 'Normal',
        startDate: '2023-02-22T23:00:00',
        planVariantId: '6364f623b8639ea9da21f828',
        planId: '6297efa4471b3f8ab4b4f5cb',
        quantity: 1,
        inheritStartDate: false,
      },
      paymentProviderSupportRefunds: false,
      billingSuspended: false,
      thresholdBillingDisabled: false,
      salesEntityId: '6294e633e636b694d8fe7a5e',
      startDate: '2023-02-22T23:00:00',
      endDate: '2026-02-22T23:00:00',
      billedUntil: '2026-02-22T23:00:00',
      planVariantId: '6364f623b8639ea9da21f828',
      customFields: {},
    },
    currentPlanVariant: {
      id: '6364f623b8639ea9da21f828',
      planId: '6297efa4471b3f8ab4b4f5cb',
      endAfterFirstContractPeriod: true,
      allowSelfService: false,
      allowWithoutPaymentData: false,
      contractPeriod: {
        quantity: 36,
        unit: 'Month',
      },
      laterContractPeriod: {
        quantity: 36,
        unit: 'Month',
      },
      cancellationPeriod: {
        quantity: 1,
        unit: 'Month',
      },
      laterCancellationPeriod: {
        quantity: 1,
        unit: 'Month',
      },
      billingPeriod: {
        quantity: 36,
        unit: 'Month',
      },
      feePeriod: {
        quantity: 1,
        unit: 'Month',
      },
      planGroupId: '6294e8bf471b3f8ab4ae46e1',
      paymentPeriodMode: 'PrePaid',
      quota: [],
      recurringFee: 0,
      setupFee: 0,
      internalName: 'Education 3Y',
      description: {},
      billingDateAlignment: 'None',
      externalId: 'PUB_EUR_EDU_36M',
      hidden: false,
      afterFirstContractPeriodNextStep: 'EndAfterFirstPeriod',
    },
    currentPlan: {
      id: '6297efa4471b3f8ab4b4f5cb',
      name: {
        _c: 'Education',
      },
      setupDescription: {
        _c: 'Setup',
      },
      planDescription: {
        _c: 'Public Cloud Education License',
      },
      trialEndPolicy: 'NoTrial',
      requiresOrderApproval: true,
      customFields: {},
      planGroupId: '6294e8bf471b3f8ab4ae46e1',
    },
    currentComponentSubscriptions: [
      {
        id: '63f70c82ac6729558498fdb2',
        contractId: '63f70c82ac6729558498fdb1',
        customerId: '63983b8cea47b985e440a173',
        componentId: '6297ed89d2044479fc041c03',
        quantity: 18,
        startDate: '2023-02-22T23:00:00',
        billedUntil: '2026-02-22T23:00:00',
        freeSeats: false,
      },
    ],
  },
}
