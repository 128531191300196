import { FC, useContext } from 'react'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../../Store.context'
import { OverviewContext } from '../../../containers/overview/Overview.context'
import WelcomeBanner from '../welcomeBanner/WelcomeBanner'
import SectionTitle from '../../../../shared/components/sectionTitle/SectionTitle'
import PlanList from '../plan/planList/PlanList'
import Summary from '../summary/Summary'
import QuickLinks from '../quickLinks/QuickLinks'
import {
  SummaryDesktopLoader,
  SummaryMobileLoader,
} from '../../../../shared/components/contentLoader/overview/summary/SummaryLoader'
import { PlanListLoader } from '../../../../shared/components/contentLoader/overview/planList/PlanListLoader'
import { checkDateIsPast } from '../../../../../utility/CommonUtility'
import {
  QuickLinksDesktopLoader,
  QuickLinksMobileLoader,
} from '../../../../shared/components/contentLoader/overview/quickLinks/QuickLinksLoader'
import { PageLoader } from '../../../../shared/components/contentLoader/page/PageLoader'
import { StoreHelper } from '../../../../../helpers/features/StoreHelper'

import './OverviewBase.scss'

const OverviewBase: FC = () => {
  const {
    planList,
    overviewSummary,
    handlePlanButtonClick,
    isOverviewInfoLoading,
    isPlanListLoading,
    overviewInfo,
  } = useContext(OverviewContext)
  const {
    userInfo,
    openPaymentModal,
    setOpenPaymentModal,
    isContentLoading,
    isCheckoutSectionAccessibility,
  } = useContext(StoreContext)
  const extraPlanTypes = [StoreHelper.PLAN_TYPES.ENTERPRISE, StoreHelper.PLAN_TYPES.EDUCATION]
  const { t } = useTranslation()

  const userName = userInfo?.name?.includes('@')
    ? userInfo?.name?.split('@')[0]
    : userInfo?.name?.split(' ')[0]

  return (
    <Container className="inner-container">
      {isContentLoading && <PageLoader />}
      <div data-test="overview-base-component" className="mb_40 overview-base">
        {/* welcome banner */}
        <Grid container direction="row">
          <Grid item xs={12}>
            <WelcomeBanner
              id={'overview-welcome-banner'}
              className={'overview__welcome-banner'}
              name={userName}
              email={userInfo?.email}
              company={userInfo?.company}
            />
          </Grid>
        </Grid>
        {/* plan list */}
        <Grid container direction="row" className="mt_20 overview__plan-list" spacing={2}>
          {isPlanListLoading ? (
            <PlanListLoader />
          ) : (
            <PlanList
              handlePlanItemClick={handlePlanButtonClick}
              planList={planList}
              activePlanType={overviewInfo?.currentPlan?.name?._c}
            />
          )}
        </Grid>
        <Grid container direction="row" className="mt_40 ">
          <Grid item xs={12} sm={12}>
            <SectionTitle
              id={'invoice-title'}
              className="overview__section-title"
              content={t('overview.paymentSummarySectionTitle')}
            />
          </Grid>
          {/* quick links */}
          <Grid item xs={12} sm={6}>
            {isOverviewInfoLoading ? (
              <div className="overview__quick-links-loaders">
                <div className="desktop">
                  <QuickLinksDesktopLoader />
                </div>
                <div className="mobile">
                  <QuickLinksMobileLoader />
                </div>
              </div>
            ) : (
              <QuickLinks
                id="overview-quick-links"
                className="overview__quick-links"
                openPaymentModal={openPaymentModal}
                setOpenPaymentModal={setOpenPaymentModal}
                teamId={userInfo?.teamId}
                isContractLock={
                  overviewInfo?.contract?.customFields?.locked_contract === 'true' ? true : false
                }
                isHideOtherLinks={
                  extraPlanTypes.includes(overviewInfo?.currentPlan?.name?._c) ||
                  !isCheckoutSectionAccessibility
                }
              />
            )}
          </Grid>
          {/* payment summary */}
          <Grid item xs={12} sm={6}>
            {isOverviewInfoLoading ? (
              <div className="overview__summary-loaders">
                <div className="desktop">
                  <SummaryDesktopLoader />
                </div>
                <div className="mobile">
                  <SummaryMobileLoader />
                </div>
              </div>
            ) : (
              <>
                {(overviewSummary?.cardInfo?.last4 || overviewSummary?.nextBillingDate) && (
                  <Summary
                    id={'overview-summary'}
                    className="overview__summary"
                    cardNumber={overviewSummary?.cardInfo?.last4}
                    nextBillingDate={overviewSummary.nextBillingDate}
                    cardIsExpired={checkDateIsPast(
                      `${overviewSummary?.cardInfo?.expiryYear}-${overviewSummary?.cardInfo?.expiryMonth}`
                    )}
                    paymentProvider={overviewInfo?.contract?.paymentProvider}
                    planType={overviewInfo?.currentPlan?.name?._c}
                    isHidePaymentInfo={!isCheckoutSectionAccessibility}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default OverviewBase
