import { Resource } from '@opentelemetry/resources'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { BatchSpanProcessor, TracerConfig, WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { trace } from '@opentelemetry/api'
import isEmpty from 'lodash/isEmpty'

import { getMetaValueByTagName } from './utility/CommonUtility'

const tracer = trace?.getTracer('ccs-store-ui-service', '1.0.0')

const METRICS_API = process.env.REACT_APP_METRICS_API_URL
  ? process.env.REACT_APP_METRICS_API_URL
  : getMetaValueByTagName('REACT_APP_METRICS_API_URL')

const OPENTELEMETRY_INSTANCE = process.env.REACT_APP_OPENTELEMETRY_INSTANCE
  ? process.env.REACT_APP_OPENTELEMETRY_INSTANCE
  : getMetaValueByTagName('REACT_APP_OPENTELEMETRY_INSTANCE')

const REACT_APP_OTEL_EXPORTER_OTLP_ENABLED = process.env.REACT_APP_OTEL_EXPORTER_OTLP_ENABLED
  ? process.env.REACT_APP_OTEL_EXPORTER_OTLP_ENABLED
  : getMetaValueByTagName('REACT_APP_OTEL_EXPORTER_OTLP_ENABLED') ?? false

const collectorOptions = {
  url: `${METRICS_API}/v1/traces`,
}
const providerConfig: TracerConfig = {
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'ccs-store-ui-service',
  }),
}

const provider = new WebTracerProvider(providerConfig)
if (
  REACT_APP_OTEL_EXPORTER_OTLP_ENABLED &&
  REACT_APP_OTEL_EXPORTER_OTLP_ENABLED !== 'false' &&
  !isEmpty(METRICS_API)
) {
  provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter(collectorOptions)))
}

provider.register({
  contextManager: new ZoneContextManager(),
})

registerInstrumentations({
  instrumentations: [
    new UserInteractionInstrumentation({
      eventNames: ['submit', 'click'],
      shouldPreventSpanCreation: (event, element, span) => {
        OPENTELEMETRY_INSTANCE && span.setAttribute('instance_name', OPENTELEMETRY_INSTANCE)
        return false
      },
    }),
  ],
})

export const traceEvent = (url: string, params: any) => {
  tracer.startActiveSpan('click', (span) => {
    params && span.setAttribute(params?.name, params?.value)
    url && span.setAttribute('api-endpoint', url)
    OPENTELEMETRY_INSTANCE && span.setAttribute('instance_name', OPENTELEMETRY_INSTANCE)
    span.end()
  })
}

export const TraceProvider = ({ children }: any) => {
  return <>{children}</>
}
