import ContentLoader from 'react-content-loader'

export const ButtonLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="80"
      height="40"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="5" ry="5" width="80" height="40" />
    </ContentLoader>
  )
}

export const ButtonListLoader = () => {
  return (
    <div className="payment-form__button-wrapper-loader">
      {ButtonLoader()} {ButtonLoader()}
    </div>
  )
}
