import { FC } from 'react'
import classnames from 'classnames'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { DropdownField, InputTextField, Label } from 'ccs-react-lib'

import ICustomerInfo from './CustomerInfo.types'
import { InputElementLoader } from '../../../../shared/components/contentLoader/planManager/inputElement/InputElementLoader'
import { StoreHelper } from '../../../../../helpers/features/StoreHelper'

import './CustomerInfo.scss'

const CustomerInfo: FC<ICustomerInfo> = (props) => {
  const {
    id,
    className,
    formProps,
    isLoading,
    countryList,
    handleFormInputChange,
    contractStatus,
  } = props
  const { t } = useTranslation()
  const languageList = [
    {
      value: 'en-US',
      label: t('planManager.customerConfiguration.language.options.english'),
    },
    {
      value: 'de',
      label: t('planManager.customerConfiguration.language.options.german'),
    },
  ]

  return (
    <div
      id={id}
      className={classnames('customer-info', className)}
      data-test="customer-info-component"
    >
      <Grid container rowSpacing={2.5}>
        <Grid item xs={12} md={12} data-test="note">
          <p className="customer-info__note">
            {t('planManager.customerConfiguration.requiredNote')}
            <span className="customer-info__required">*</span>
          </p>
        </Grid>
        <Grid item xs={12} md={12} data-test="full-name">
          <Grid container direction="row" spacing={2.5}>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="first-name"
                  inputName="firstName"
                  placeholderText={t('planManager.customerConfiguration.firstName.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.firstName}
                  isRequired
                  inputType="text"
                  labelText={t('planManager.customerConfiguration.firstName.label')}
                  isError={formProps.errors.firstName && formProps.touched.firstName}
                  errorMessage={formProps.errors.firstName}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="last-name"
                  inputName="lastName"
                  placeholderText={t('planManager.customerConfiguration.lastName.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.lastName}
                  isRequired
                  inputType="text"
                  labelText={t('planManager.customerConfiguration.lastName.label')}
                  isError={formProps.errors.lastName && formProps.touched.lastName}
                  errorMessage={formProps.errors.lastName}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} data-test="company-name">
          {isLoading ? (
            <InputElementLoader />
          ) : (
            <InputTextField
              id="company-name"
              inputName="companyName"
              placeholderText={t('planManager.customerConfiguration.companyName.label')}
              autoComplete="off"
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              value={formProps.values.companyName}
              inputType="text"
              labelText={t('planManager.customerConfiguration.companyName.label')}
              isError={formProps.errors.companyName && formProps.touched.companyName}
              errorMessage={formProps.errors.companyName}
              isRequired
            />
          )}
        </Grid>
        <Grid item xs={12} md={7} data-test="country">
          {isLoading ? (
            <InputElementLoader />
          ) : (
            <DropdownField
              name="country"
              id="country"
              value={formProps.values.country}
              placeholderText={t('planManager.customerConfiguration.country.options.select')}
              onChange={(e: any) =>
                handleFormInputChange(formProps, {
                  name: 'country',
                  value: e.target.value,
                })
              }
              onBlur={formProps.handleBlur}
              isDisabled={contractStatus === StoreHelper.CONTRACT_STATUS.ACTIVE}
              options={
                countryList
                  ? countryList?.map((country: any) => ({
                      text: country.label,
                      value: country.value,
                    }))
                  : []
              }
              isRequired
              labelText={t('planManager.customerConfiguration.country.label')}
              isError={formProps.errors.country && formProps.touched.country}
              errorMessage={formProps.errors.country}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} data-test="address">
          <Grid container direction="row" rowSpacing={1.5}>
            <Grid item xs={12} md={12}>
              {' '}
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="address-line-1"
                  inputName="addressLineOne"
                  placeholderText={t('planManager.customerConfiguration.addressLine1.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.addressLineOne}
                  className="input__adressline"
                  inputType="text"
                  labelText={t('planManager.customerConfiguration.address.label')}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              {isLoading ? (
                <div className="row-item">
                  <InputElementLoader />
                </div>
              ) : (
                <InputTextField
                  id="address-line-2"
                  className="address-line-2"
                  inputName="addressLineTwo"
                  placeholderText={t('planManager.customerConfiguration.addressLine2.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.addressLineTwo}
                  inputType="text"
                  labelText="address-line-2"
                  inputProps={{
                    'aria-labelledby': 'address-line-1-label',
                    'aria-multiline': true,
                    'aria-label': 'address-line-1-label',
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} data-test="street">
          <Grid container direction="row" columnSpacing={2.5}>
            <Grid item xs={12} sm={8}>
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="street"
                  inputName="street"
                  placeholderText={t('planManager.customerConfiguration.street.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.street}
                  inputType="text"
                  labelText={t('planManager.customerConfiguration.street.label')}
                  isError={formProps.errors.street && formProps.touched.street}
                  errorMessage={formProps.errors.street}
                  isRequired
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="street-number"
                  inputName="streetNumber"
                  placeholderText={t('planManager.customerConfiguration.houseNo.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.streetNumber}
                  inputType="text"
                  labelText={t('planManager.customerConfiguration.houseNo.label')}
                  isError={formProps.errors.streetNumber && formProps.touched.streetNumber}
                  errorMessage={formProps.errors.streetNumber}
                  isRequired
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} data-test="city">
          <Grid container direction="row" columnSpacing={2.5}>
            <Grid item xs={12} sm={3.5}>
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="city-number"
                  inputName="cityNumber"
                  placeholderText={t('planManager.customerConfiguration.postalCode.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.cityNumber}
                  inputType="text"
                  labelText={t('planManager.customerConfiguration.postalCode.label')}
                  isError={formProps.errors.cityNumber && formProps.touched.cityNumber}
                  errorMessage={formProps.errors.cityNumber}
                  isRequired
                />
              )}
            </Grid>
            <Grid item xs={12} sm={8.5}>
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="city"
                  inputName="city"
                  placeholderText={t('planManager.customerConfiguration.city.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values.city}
                  inputType="text"
                  labelText={t('planManager.customerConfiguration.city.label')}
                  isError={formProps.errors.city && formProps.touched.city}
                  errorMessage={formProps.errors.city}
                  isRequired
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} data-test="eu-vat-id">
          <Grid container direction="row">
            <Grid item xs={12} sm={12} className="row-item">
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="eu-vat-id"
                  inputName="euVatId"
                  className="eu-vat-id"
                  placeholderText={t('planManager.customerConfiguration.euVat.label')}
                  autoComplete="off"
                  onChange={formProps.handleChange}
                  value={formProps.values.euVatId}
                  onBlur={formProps.handleBlur}
                  inputType="text"
                  labelText={t('planManager.customerConfiguration.euVat.label')}
                  labelVisibility={true}
                  labelDescription={t('planManager.customerConfiguration.euVat.description')}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} data-test="invoice-email">
          {isLoading ? (
            <InputElementLoader />
          ) : (
            <InputTextField
              id="invoice-email"
              inputName="invoiceEmail"
              placeholderText={t('planManager.customerConfiguration.email.label')}
              autoComplete="off"
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              value={formProps.values.invoiceEmail}
              inputType="text"
              labelText={t('planManager.customerConfiguration.email.label')}
              labelDescription={t('planManager.customerConfiguration.email.description')}
              textFieldHint={t('planManager.customerConfiguration.email.hint')}
              textFieldHintVisibility={true}
              isError={formProps.errors.invoiceEmail && formProps.touched.invoiceEmail}
              errorMessage={formProps.errors.invoiceEmail}
              isRequired
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} data-test="invoice-language">
          <Grid container direction="row">
            <Grid item xs={12} sm={12}>
              <Label id="euVat" text={t('planManager.customerConfiguration.language.label')} />
            </Grid>
            <Grid item xs={12} sm={4} className="row-item">
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <DropdownField
                  id="invoice-language"
                  name="invoiceLanguage"
                  value={formProps.values.invoiceLanguage}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  placeholderText={t('planManager.customerConfiguration.language.options.select')}
                  options={languageList?.map((language: any) => ({
                    text: language.label,
                    value: language.value,
                  }))}
                  labelText={t('planManager.customerConfiguration.language.label')}
                  labelVisibility={false}
                  isError={formProps.errors.invoiceLanguage && formProps.touched.invoiceLanguage}
                  errorMessage={formProps.errors.invoiceLanguage}
                  isRequired
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default CustomerInfo
