import isNil from 'lodash/isNil'

import { getMetaValueByTagName } from '../utility/CommonUtility'
import { LocalStorageHelper } from '../helpers/LocalStorageHelper'
import StorageUtitlity from '../utility/StorageUtility'

export const GeneralHelper = Object({
  APP_CONTENT: 'Store',
  APP_TITLE: 'Conceptboard Store',
  APP_URL: StorageUtitlity.getSingleData(LocalStorageHelper.RETURN_URL)
    ? StorageUtitlity.getSingleData(LocalStorageHelper.RETURN_URL)
    : 'https://app.conceptboard.com ',
  USER_INFORMATION: {
    id: 2,
    name: 'John Wick',
    email: 'john.wick@conceptboard.com',
    company: 'Dream Team',
  },
  LOGO_IMAGE_URL: 'https://conceptboard.com/wp-content/uploads/Conceptboard_Logo_color.png',
  BW_API_URL: !isNil(process.env.REACT_APP_STORE_API_URL)
    ? process.env.REACT_APP_STORE_API_URL
    : getMetaValueByTagName('REACT_APP_STORE_API_URL'),
  APP_HELP_CENTER_URL: 'https://help.conceptboard.com/',
  MATOMA_INSTANCE: {
    BASE_URL: 'https://conceptboard.matomo.cloud/',
    PROD_SITE_ID: '2',
    INTEGRATION_SITE_ID: '4',
  },
  APP_ENV: {
    LOCAL: 'local',
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
  },
  MATOMO_LOGS: {
    PAGE_TITLE: {
      OVERVIEW_SECTION: 'Overview Section',
      CHECKOUT_SECTION: 'Checkout Section',
      INVOICE_SECTION: 'Invoice History',
    },
  },
  BILLWERK_SELFSERVICE_URL: !isNil(process.env.REACT_APP_BILLWERK_SELFSERVICE_URL)
    ? process.env.REACT_APP_BILLWERK_SELFSERVICE_URL
    : getMetaValueByTagName('REACT_APP_BILLWERK_SELFSERVICE_URL'),
})
