import { useEffect } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'ccs-react-lib'

import { GeneralHelper } from '../../../../helpers/GeneralHelper'
import { getReturnUrl, replaceParameters } from '../../../../utility/CommonUtility'
import { MotomoMethod, setMatomoAnalytics } from '../../../../utility/MatomoAnalytics'

import ErrorImage from '../../../../assets/images/error-icon.svg'

import './ErrorMessage.scss'

const ErrorMessage = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  /**
   * handle back button click
   */
  const handleBackButtonClick = () => {
    const returnUrl = getReturnUrl()
    window.open(returnUrl || '', '_self')
  }

  useEffect(() => {
    const statusCode = searchParams.get('status')
    const documentTitle = replaceParameters(t('matomoLog.pageTitle.errorPage'), { statusCode })
    const url = window.location.href
    const urlObj = new URL(url)
    urlObj.search = ''

    if (statusCode) {
      const pageInfo = {
        type: MotomoMethod.TRACK_PAGE_VIEW,
        documentTitle: documentTitle,
        href: urlObj.toString(),
      }
      setMatomoAnalytics(pageInfo)
      searchParams.delete('status')
      setSearchParams(searchParams)
    }
  }, [])

  return (
    <Container className="inner-container">
      <div
        id={'error-page-message'}
        className={'error-page-message'}
        data-test="error-message-content"
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="h4" data-test="app-name">
              {GeneralHelper.APP_TITLE}
            </Typography>
          </Grid>

          <Grid item xs={12} className="message-wrapper">
            <img src={ErrorImage} alt="no-data" data-test="error-message-icon" />
            <Typography
              variant="h5"
              className="error-message__text mt_10"
              data-test="error-message-text"
            >
              {t('errorPage.mainText')}
            </Typography>
            <Typography
              variant="body2"
              className="error-message__description mt_5"
              data-test="error-message-description"
            >
              {t('errorPage.subText')}
            </Typography>
            <Button
              id={'back-to-app-btn'}
              className="mt_20 back-to-app"
              onClick={() => handleBackButtonClick()}
              content={t('errorPage.buttonText')}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default ErrorMessage
