import { GeneralHelper } from '../../helpers/GeneralHelper'
import { traceEvent } from '../../tracing'
import {
  MotomoEventAction,
  MotomoEventCategory,
  MotomoMethod,
  setMatomoAnalytics,
} from '../../utility/MatomoAnalytics'
import * as ApiConnector from '../ApiConnector'

/**
 * to getting all invoices
 * for the logged in company
 *  @param {params} params
 * return array
 */
export const getInvoicesList = async (params: any, teamId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/invoices`
  traceEvent(url, { name: 'page', value: params?.page })
  try {
    const { data } = await ApiConnector.get(url, { params })
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * to getting the invoice details for the download
 * @param invoiceId
 * @returns
 */
export const getDownloadableInvoiceById = async (invoiceId: string, teamId: string) => {
  const config = {
    responseType: 'blob',
  }
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/invoices/download/${invoiceId}`
  const matomoLogObj = {
    type: MotomoMethod.TRACK_EVENT,
    category: MotomoEventCategory.USER_ENGAGEMENT,
    action: MotomoEventAction.DOWNLOAD_INVOICE,
    value: JSON?.stringify({ 'team-id': teamId, 'invoice-id': invoiceId }),
  }
  setMatomoAnalytics(matomoLogObj)
  try {
    const { data } = await ApiConnector.get(url, config)
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * check the token validity status
 * @param token
 * @returns
 */
export const checkTokenValidity = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const url = `${GeneralHelper.BW_API_URL}/api/v1/auth`

  try {
    const { status } = await ApiConnector.post(url, '', config)
    return status
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * getr contract information
 * @returns contract info
 */
export const getContractInfo = async (teamId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/checkout/info`
  traceEvent(url, { name: 'team-id', value: teamId })
  try {
    const { data } = await ApiConnector.get(url)
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * get the all plan list with the billing period
 * @param params
 * @returns plan list
 */
export const getPlanListByPeriod = async (params: any, teamId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/plans/plan-info/${params?.billingPeriod}/${params?.currency}`

  try {
    const { data } = await ApiConnector.get(url)
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * get self service token by the contract id
 * @param params
 * @returns token info
 */
export const getSelfServiceTokenByContract = async (contractId: any, teamId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/contracts/${contractId}/self-service-token`

  try {
    const { data } = await ApiConnector.get(url)
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * update the customer information
 * @param body
 * @param teamId
 * @returns
 */
export const updateCustomerInfo = async (body: any, teamId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/checkout/customer`
  traceEvent(url, { name: 'team-id', value: teamId })
  const matomoLogObj = {
    type: MotomoMethod.TRACK_EVENT,
    category: MotomoEventCategory.USER_ENGAGEMENT,
    action: MotomoEventAction.UPDATE_CUSTOMER_INFO,
    value: JSON?.stringify({ 'team-id': teamId }),
  }
  setMatomoAnalytics(matomoLogObj)
  try {
    const data = await ApiConnector.put(url, body, {})
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * update the seat count info in-trail / active
 * @param body
 * @param teamId
 * @returns
 */
export const updateSeatCountInfo = async (body: any, teamId: string, contractStatus: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/checkout/${contractStatus}/quantity`

  try {
    const { data } = await ApiConnector.put(url, body, {})
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * update the plan info in-trail / active
 * @param body
 * @param teamId
 * @returns
 */
export const updatePlanInfo = async (body: any, teamId: string, contractStatus: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/checkout/${contractStatus}/plan-variant`
  const matomoLogObj = {
    type: MotomoMethod.TRACK_EVENT,
    category: MotomoEventCategory.USER_ENGAGEMENT,
    action: MotomoEventAction.UPDATE_PLAN_INFO,
    value: JSON?.stringify({ 'team-id': teamId }),
  }
  setMatomoAnalytics(matomoLogObj)
  try {
    const { data } = await ApiConnector.put(url, body, {})
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * update the order confirmation
 * @param orderId
 * @param teamId
 * @returns
 */
export const confirmOrder = async (orderId: any, teamId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/checkout/confirm/${orderId}`
  try {
    const data = await ApiConnector.post(url, {}, {})
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * get country list
 * @param teamId
 * @returns
 */
export const getCountryList = async (teamId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/checkout/countries`
  try {
    const { data } = await ApiConnector.get(url)
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * create new contract
 * @param body
 * @param teamId
 * @returns
 */
export const createNewContract = async (body: any, teamId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/checkout/force-contract-phase`

  try {
    const { data } = await ApiConnector.post(url, body, {})
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}

/**
 * unlock the contract
 * @param body
 * @param teamId
 * @param contractId
 * @returns
 */
export const unlockContract = async (teamId: string, contractId: string) => {
  const url = `${GeneralHelper.BW_API_URL}/api/v1/${teamId}/contracts/unlock/${contractId}`

  try {
    const data = await ApiConnector.post(url, {}, {})
    return data
  } catch (error) {
    console.error(`error ${error}`)
  }
}
