import { createContext, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import size from 'lodash/size'

import { StoreHelper } from '../../../../helpers/features/StoreHelper'
import { RouteHelper } from '../../../../helpers/RouteHelper'
import { IOverviewContext } from './Overview.types'
import { IPlan } from '../../components/overview/plan/planList/PlanList.types'
import i18n from '../../../../translations/i18n'
import { StoreContext } from '../../Store.context'
import {
  checkCheckoutSectionAccessibility,
  decodeJwt,
  getMetaValueByTagName,
  replaceParameters,
} from '../../../../utility/CommonUtility'
import {
  confirmOrder,
  createNewContract,
  getContractInfo,
  getPlanListByPeriod,
  unlockContract,
} from '../../../../api/store/StoreApi'
import { IManagePlanInfo } from '../planManager/PlanManager.types'
import { getBillingPeriodText } from '../planManager/PlanManager.context'
import StorageUtitlity from '../../../../utility/StorageUtility'
import { LocalStorageHelper } from '../../../../helpers/LocalStorageHelper'
import { dateFormatter } from '../../../../utility/DateUtility'
import { MotomoMethod, setMatomoAnalytics } from '../../../../utility/MatomoAnalytics'

export const OverviewContext = createContext<IOverviewContext>({} as IOverviewContext)

const OverviewProvider = (props: { children: any }) => {
  const { children } = props
  const navigate = useNavigate()
  const {
    userInfo,
    setContractId,
    updatePaymentInfoSuccess,
    setUpdatePaymentInfoSuccess,
    setIsContentLoading,
    setIsCheckoutSectionAccessibility,
  } = useContext(StoreContext)
  const [isPlanListLoading, setIsPlanListLoading] = useState(false)
  const [planList, setPlanList] = useState<IPlan[]>([])
  const [overviewSummary, setOverviewSummary] = useState({})
  const [overviewInfo, setOverviewInfo] = useState<IManagePlanInfo>({})
  const [isOverviewInfoLoading, setIsOverviewInfoLoading] = useState(true)
  const [numberOfSeatCountInToken, setNumberOfSeatCountInToken] = useState(0)
  const teamId = userInfo?.teamId

  let enterpriseOrEducationPlan = {
    id: 'ent-01',
    title: 'Enterprise',
    price: t('overview.planInfo.enterprisePriceText'),
    description: t('overview.planInfo.enterpriseDescriptionText'),
    subDescription: t('overview.planInfo.enterpriseInfoText'),
    buttonText: t('overview.planInfo.learnMoreButtonText'),
    currencyVisibility: false,
    componentId: '',
    isActive: false,
    activeLabel: '',
  }

  useEffect(() => {
    setIsPlanListLoading(true)
    const pageInfo = {
      type: MotomoMethod.TRACK_PAGE_VIEW,
      documentTitle: t('matomoLog.pageTitle.overviewSection'),
      href: window.location.href,
    }
    setMatomoAnalytics(pageInfo)
    const token = StorageUtitlity.getSingleData(LocalStorageHelper.ACCESS_TOKEN)
    if (token) {
      const tokenInfo = decodeJwt(token)
      setNumberOfSeatCountInToken(
        tokenInfo['https://conceptboard.com/ns/oauth#claim_numberofusedseats'] || 0
      )
    }
  }, [])

  useEffect(() => {
    if (teamId) {
      fetchOverviewInfo(false)
    }
  }, [teamId])

  useEffect(() => {
    if (!isEmpty(overviewInfo)) {
      const summaryObj = {
        cardInfo: overviewInfo?.contract?.paymentBearer,
        nextBillingDate: overviewInfo?.contract?.nextBillingDate,
      }
      setOverviewSummary(summaryObj)
      const planParams = {
        billingPeriod: overviewInfo?.currentPlanVariant?.billingPeriod?.quantity
          ? `${overviewInfo.currentPlanVariant.billingPeriod.quantity}M`
          : '12M',
        currency: overviewInfo?.contract?.currency,
      }

      const checkoutSectionAccessibility = checkCheckoutSectionAccessibility(
        process.env.REACT_APP_CHECKOUT_SECTION_ACCESSIBILITY ||
          getMetaValueByTagName('REACT_APP_CHECKOUT_SECTION_ACCESSIBILITY'),
        overviewInfo?.currentPlan?.name?._c
      )

      fetchPlanListByPeriod(planParams, overviewInfo, checkoutSectionAccessibility)
      // checked the plan info and set status
      setIsCheckoutSectionAccessibility?.(checkoutSectionAccessibility)
    }
  }, [overviewInfo])

  useEffect(() => {
    if (!isEmpty(updatePaymentInfoSuccess)) {
      setUpdatePaymentInfoSuccess({})
      if (
        overviewInfo?.contract?.lifecycleStatus === StoreHelper.CONTRACT_STATUS.TRIAL &&
        isEmpty(overviewInfo?.contract?.paymentProvider)
      ) {
        createNewContractPahse(overviewInfo)
      } else {
        setIsContentLoading(false)
      }
      if (overviewInfo?.contract?.customFields?.locked_contract === 'true') {
        unlockCurrentContract(overviewInfo)
      } else {
        fetchOverviewInfo(!isEmpty(overviewInfo?.contract?.paymentProvider))
      }
    }
  }, [updatePaymentInfoSuccess])
  /**
   * handle the plan button click event
   * @param planItem
   */
  const handlePlanButtonClick = (planItem: any) => {
    const planManagerPath = replaceParameters(RouteHelper.PATH.PLAN_MANAGER_LICENSE_SECTION, {
      teamId,
    })
    if (planItem.title !== StoreHelper.PLAN_TYPES.ENTERPRISE) {
      if (planItem?.isActive) {
        navigate(`${planManagerPath}`)
      } else {
        navigate(`${planManagerPath}`, {
          state: {
            planId: planItem.id,
            planTitle: planItem.title,
          },
        })
      }
    } else {
      if (i18n.language == 'de') {
        window.open(StoreHelper.LEARN_MORE_LINK.de, '_blank')
      } else {
        window.open(StoreHelper.LEARN_MORE_LINK.en, '_blank')
      }
    }
  }

  /**
   * fetch overview info
   *  @param isUpdatePaymentInfo
   */
  const fetchOverviewInfo = async (isUpdatePaymentInfo = false) => {
    try {
      setIsOverviewInfoLoading(true)
      const data = await getContractInfo(userInfo?.id)
      if (isUpdatePaymentInfo) {
        const updatedContractInfo: any = data
        const summaryObj = {
          cardInfo: updatedContractInfo?.contract?.paymentBearer,
          nextBillingDate: updatedContractInfo?.contract?.nextBillingDate,
        }
        setOverviewSummary(summaryObj)
      } else {
        setOverviewInfo(data)
      }
      if (data?.contract?.id) {
        setContractId?.(data.contract.id)
      }
      setIsOverviewInfoLoading(false)
    } catch (error) {
      setOverviewInfo({})
      setIsOverviewInfoLoading(false)
    }
  }

  /**
   * get dynamic plan description
   * @param lifecycleStatus
   * @param totalSeatCount
   * @param lastBillingDate
   * @returns description
   */
  const getDynamicPlanDescription = (
    lifecycleStatus: string,
    totalSeatCount: number,
    lastBillingDate?: string,
    planType?: string,
    freeSeatCount?: number
  ): string => {
    let description = replaceParameters(
      planType === StoreHelper.PLAN_TYPES.EDUCATION
        ? t('overview.activePlan.educationSeatInfo')
        : t('overview.activePlan.seatInfo'),
      {
        numberOfSeatCountInToken,
      }
    )
    if (lifecycleStatus === StoreHelper.CONTRACT_STATUS.ACTIVE) {
      description += replaceParameters(t('overview.activePlan.totalSeatInfo'), { totalSeatCount })
      if (planType === StoreHelper.PLAN_TYPES.EDUCATION) {
        description += replaceParameters(t('overview.activePlan.educationStudentCount'), {
          studentCount: freeSeatCount,
        })
      }
      if (!isNil(lastBillingDate)) {
        description += replaceParameters(t('overview.activePlan.lastBillingDate'), {
          lastBillingDate: dateFormatter(lastBillingDate),
        })
      }
    }
    return description
  }

  /**
   * fetch plan list by period
   * @param params
   */
  const fetchPlanListByPeriod = async (params: any, baseInfo: any, isAccessibility?: boolean) => {
    try {
      setIsPlanListLoading(true)
      const billingPeriod = baseInfo?.currentPlanVariant?.billingPeriod?.quantity ?? 12
      //free seat info
      const freeSeatCountInfo = baseInfo?.currentComponentSubscriptions?.find(
        (sub: any) => sub.freeSeats === true
      )
      //regular seat info
      const regularSeatCountInfo = baseInfo?.currentComponentSubscriptions?.find(
        (sub: any) => sub.freeSeats === false
      )
      const freeSeatCount = freeSeatCountInfo?.quantity ? freeSeatCountInfo?.quantity : 0
      const totalSeatCount =
        freeSeatCount + (regularSeatCountInfo?.quantity ? regularSeatCountInfo?.quantity : 0)
      const lastBillingDate = baseInfo?.contract?.lastBillingDate
      const extraPlanTypes = [StoreHelper.PLAN_TYPES.ENTERPRISE, StoreHelper.PLAN_TYPES.EDUCATION]

      const { items } = await getPlanListByPeriod(params, userInfo?.id)
      const planParams = {
        billingPeriod: '12M',
        currency: overviewInfo?.contract?.currency,
      }
      const plantItemsWithDefaultPrice = await getPlanListByPeriod(planParams, userInfo?.id)
      if (items.length > 0 && plantItemsWithDefaultPrice?.items.length > 0) {
        items.map((planData: any) => {
          if (planData.title === StoreHelper.PLAN_TYPES.PREMIUM) {
            // for PREMIUM plan
            if (isNil(planData.price)) {
              planData.price = 0
            }
            const defaultPlanInfo = plantItemsWithDefaultPrice?.items?.find(
              (plan: any) => plan.title == StoreHelper.PLAN_TYPES.PREMIUM
            )
            const isActive = baseInfo?.currentPlan?.name?._c === StoreHelper.PLAN_TYPES.PREMIUM
            planData.id = planData.planId
            planData.description = replaceParameters(
              t('planManager.licenseConfiguration.planInfo.premiumInfoText'),
              { billingPeriod: getBillingPeriodText(12) }
            )
            planData.subDescription = t('overview.planInfo.premiumInfoText')
            planData.buttonText =
              baseInfo?.currentPlan?.name?._c === StoreHelper.PLAN_TYPES.PREMIUM
                ? t('overview.planInfo.managePlanButtonText')
                : t('overview.planInfo.selectPlanButtonText')
            planData.currencyVisibility = true
            planData.isActive = isActive
            planData.price = isActive ? planData.price.toFixed(2) : defaultPlanInfo.price.toFixed(2)
            planData.activeLabel = isEmpty(baseInfo?.contract?.paymentProvider)
              ? t('overview.planInfo.trialLabel')
              : t('overview.planInfo.activeLabel')
            if (isActive && isAccessibility) {
              planData.description = replaceParameters(
                t('planManager.licenseConfiguration.planInfo.premiumInfoText'),
                { billingPeriod: getBillingPeriodText(billingPeriod) }
              )
              planData.subDescription = getDynamicPlanDescription(
                baseInfo?.contract?.lifecycleStatus,
                totalSeatCount,
                lastBillingDate,
                StoreHelper.PLAN_TYPES.PREMIUM,
                freeSeatCount
              )
            }
            if (extraPlanTypes.includes(baseInfo?.currentPlan?.name?._c) || !isAccessibility) {
              planData.price = ''
              planData.buttonText = ''
            }
            return planData
          } else if (planData.title === StoreHelper.PLAN_TYPES.BUSINESS) {
            // for BUSINESS plan
            if (isNil(planData.price)) {
              planData.price = 0
            }
            const defaultPlanInfo = plantItemsWithDefaultPrice?.items?.find(
              (plan: any) => plan.title == StoreHelper.PLAN_TYPES.BUSINESS
            )
            const isActive = baseInfo?.currentPlan?.name?._c === StoreHelper.PLAN_TYPES.BUSINESS
            planData.id = planData.planId
            planData.description = replaceParameters(
              t('planManager.licenseConfiguration.planInfo.businessInfoText'),
              { billingPeriod: getBillingPeriodText(12) }
            )
            planData.subDescription = t('overview.planInfo.businessInfoText')
            planData.buttonText =
              baseInfo?.currentPlan?.name?._c === StoreHelper.PLAN_TYPES.BUSINESS
                ? t('overview.planInfo.managePlanButtonText')
                : t('overview.planInfo.selectPlanButtonText')
            planData.currencyVisibility = true
            planData.isActive = isActive
            planData.price = isActive ? planData.price.toFixed(2) : defaultPlanInfo.price.toFixed(2)
            planData.activeLabel = isEmpty(baseInfo?.contract?.paymentProvider)
              ? t('overview.planInfo.trialLabel')
              : t('overview.planInfo.activeLabel')
            if (isActive && isAccessibility) {
              planData.description = replaceParameters(
                t('planManager.licenseConfiguration.planInfo.businessInfoText'),
                { billingPeriod: getBillingPeriodText(billingPeriod) }
              )
              planData.subDescription = getDynamicPlanDescription(
                baseInfo?.contract?.lifecycleStatus,
                totalSeatCount,
                lastBillingDate,
                StoreHelper.PLAN_TYPES.BUSINESS,
                freeSeatCount
              )
            }
            if (extraPlanTypes.includes(baseInfo?.currentPlan?.name?._c) || !isAccessibility) {
              planData.price = ''
              planData.buttonText = ''
            }
            return planData
          }
        })

        //when the ENTERPRISE plan is active
        if (baseInfo?.currentPlan?.name?._c === StoreHelper.PLAN_TYPES.ENTERPRISE) {
          enterpriseOrEducationPlan = {
            ...enterpriseOrEducationPlan,
            isActive: true,
            activeLabel: isEmpty(baseInfo?.contract?.paymentProvider)
              ? t('overview.planInfo.trialLabel')
              : t('overview.planInfo.activeLabel'),
            buttonText: '',
            description: '',
            price: '',
            subDescription: getDynamicPlanDescription(
              baseInfo?.contract?.lifecycleStatus,
              totalSeatCount,
              lastBillingDate,
              StoreHelper.PLAN_TYPES.ENTERPRISE,
              freeSeatCount
            ),
          }
        }
        if (
          baseInfo?.currentPlan?.name?._c !== StoreHelper.PLAN_TYPES.ENTERPRISE &&
          !isAccessibility
        ) {
          enterpriseOrEducationPlan = {
            ...enterpriseOrEducationPlan,
            price: '',
          }
        }

        //when the EDUCATION plan is active
        if (baseInfo?.currentPlan?.name?._c === StoreHelper.PLAN_TYPES.EDUCATION) {
          //student seat info
          const studentSeatsCountInfo = baseInfo?.currentComponentSubscriptions?.find(
            (sub: any) => sub.studentSeats === true
          )
          //regular seat info
          const regularStudentSeatsCountInfo = baseInfo?.currentComponentSubscriptions?.find(
            (sub: any) => sub.studentSeats === false
          )

          const studentSeatsCount = studentSeatsCountInfo?.quantity
            ? studentSeatsCountInfo?.quantity
            : 0
          const regularStudentSeatsCount = regularStudentSeatsCountInfo?.quantity
            ? regularStudentSeatsCountInfo?.quantity
            : 0

          enterpriseOrEducationPlan = {
            ...enterpriseOrEducationPlan,
            id: 'edu-01',
            title: 'Education',
            isActive: true,
            activeLabel: isEmpty(baseInfo?.contract?.paymentProvider)
              ? t('overview.planInfo.trialLabel')
              : t('overview.planInfo.activeLabel'),
            buttonText: '',
            description: '',
            price: '',
            subDescription: getDynamicPlanDescription(
              baseInfo?.contract?.lifecycleStatus,
              regularStudentSeatsCount,
              lastBillingDate,
              StoreHelper.PLAN_TYPES.EDUCATION,
              studentSeatsCount
            ),
          }
        }
        items.push(enterpriseOrEducationPlan)
        setPlanList(items)
      }
      setIsPlanListLoading(false)
    } catch (error) {
      setPlanList([])
      setIsPlanListLoading(false)
    }
  }

  /**
   * create new contract pahse
   * @param contractInfo
   */
  const createNewContractPahse = async (contractInfo: any) => {
    setIsContentLoading(true)
    //free seat info
    const freeSeatCountInfo = contractInfo?.currentComponentSubscriptions?.find(
      (sub: any) => sub.freeSeats === true
    )
    //regular seat info
    const regularSeatCountInfo = contractInfo?.currentComponentSubscriptions?.find(
      (sub: any) => sub.freeSeats === false
    )
    const freeSeatCount = freeSeatCountInfo?.quantity ? freeSeatCountInfo?.quantity : 0
    const totalSeatCount =
      freeSeatCount + (regularSeatCountInfo?.quantity ? regularSeatCountInfo?.quantity : 0)

    const oldComponentsArray: any = []

    if (freeSeatCount === 0) {
      oldComponentsArray.push(regularSeatCountInfo?.componentId)
    } else {
      oldComponentsArray.push(regularSeatCountInfo?.componentId, freeSeatCountInfo?.componentId)
    }

    const futureComponentSubscriptionInfo = !isNil(contractInfo?.futureComponentSubscription)
      ? contractInfo?.futureComponentSubscription
      : {}

    if (!isNil(futureComponentSubscriptionInfo) || !isEmpty(futureComponentSubscriptionInfo)) {
      size(futureComponentSubscriptionInfo) > 0 &&
        futureComponentSubscriptionInfo?.map((info: any) => {
          oldComponentsArray.push(info.componentId)
        })
    }
    const licenseInfo = {
      planVariantId: contractInfo?.contract?.currentPhase?.planVariantId,
      numberOfSeats:
        numberOfSeatCountInToken > totalSeatCount
          ? numberOfSeatCountInToken
          : regularSeatCountInfo?.quantity,
      numberOfFreeSeats: freeSeatCountInfo?.quantity ? freeSeatCountInfo?.quantity : null,
      contractId: contractInfo?.contract?.id,
      oldComponents: uniq(oldComponentsArray),
      componentId: regularSeatCountInfo?.componentId,
    }
    try {
      const contractResponse = await createNewContract(licenseInfo, userInfo?.id)
      if (!isNil(contractResponse)) {
        const orderResponse = await confirmOrder(
          contractResponse?.confirmationInfo?.orderId,
          userInfo?.id
        )
      }
      setIsContentLoading(false)
    } catch (error) {
      setIsContentLoading(false)
    }
  }

  /**
   * unlock the contract
   * @param contractInfo
   */
  const unlockCurrentContract = async (contractInfo: any) => {
    try {
      const data = await unlockContract(userInfo?.id, contractInfo?.contract?.id)
      if (data?.status === 200) {
        fetchOverviewInfo(false)
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <OverviewContext.Provider
      value={{
        isPlanListLoading,
        planList,
        overviewSummary,
        isOverviewInfoLoading,
        overviewInfo,
        handlePlanButtonClick,
      }}
    >
      {children}
    </OverviewContext.Provider>
  )
}

export default OverviewProvider
