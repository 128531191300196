import { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import HelpOutline from '@mui/icons-material/HelpOutline'
import { Logo, Title } from 'ccs-react-lib'
import { useTranslation } from 'react-i18next'

import UserInfo from '../../../shared/components/userInfo/UserInfo'
import { GeneralHelper } from '../../../../helpers/GeneralHelper'
import { RouteHelper } from '../../../../helpers/RouteHelper'
import {
  getMetaValueByTagName,
  checkActionAvailability,
  replaceParameters,
  getReturnUrl,
} from '../../../../utility/CommonUtility'
import IHeader from './Header.types'

import LogoImage from '../../../../assets/images/logo-store.png'

import './Header.scss'

const Header: FC<IHeader> = (props) => {
  const { id, className, userInfo } = props
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const teamId = userInfo?.teamId

  const popoverIsOpen = Boolean(anchorEl)

  const actionAvailability = checkActionAvailability(
    process.env.REACT_APP_ENVIRONMENT || getMetaValueByTagName('REACT_APP_ENVIRONMENT')
  )

  const overviewPath = replaceParameters(RouteHelper.PATH.OVERVIEW, { teamId })
  const returnUrl = getReturnUrl()

  const backButtonLink = () => (
    <a href={returnUrl} rel="noreferrer">
      <KeyboardBackspaceIcon className="header__back-icon" data-test="header-back-to-app-icon" />
      <Typography
        className="header__back-link"
        variant="caption"
        data-test="header-back-to-app-text"
        id="link-back-to-app"
      >
        {t('header.backToAppText')}
      </Typography>
    </a>
  )

  const helpIconLink = () => (
    <a
      href={GeneralHelper.APP_HELP_CENTER_URL}
      rel="noreferrer"
      className="header__help-icon-link"
      target={'_blank'}
      aria-label="help icon"
    >
      <HelpOutline className="header__help-icon" data-test="header-help-icon" />
    </a>
  )

  useEffect(() => {
    const logoEl = document.getElementById('header-logo')
    logoEl?.parentElement?.setAttribute('tabindex', '-1')
  }, [])

  return (
    <div id={id} className={classnames('header', className)} data-test="header-component">
      <Grid container>
        <Grid item xl={6} md={6} sm={6} xs={11}>
          <div className="header__logo-content">
            <Link
              className={classnames({
                'action-disabled': !actionAvailability,
              })}
              to={overviewPath}
            >
              <Logo
                id={'header-logo'}
                size={'small'}
                imageUrl={LogoImage}
                alt={GeneralHelper.APP_TITLE}
              />

              <span className="header__separator mr_5 ml_5">|</span>
              <Title
                id={'header-title'}
                className="header__title"
                transform="uppercase"
                variant="caption"
                weight="bold"
                content={GeneralHelper.APP_CONTENT}
              />
            </Link>
          </div>
        </Grid>
        <Grid className="header__more-icon-wrapper  text--right" item xs={1}>
          <MoreVertIcon
            className="header__more-icon"
            data-test="header-more-icon"
            onClick={(event: any) => setAnchorEl(event.currentTarget)}
          />
          <Popover
            open={popoverIsOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="header__back-link-mobile-content" data-test="header-back-to-app">
              {backButtonLink()}
              <span className="header__separator mr_5 ml_5">|</span>
              {helpIconLink()}
            </div>
          </Popover>
        </Grid>
        <Grid item xl={6} md={6} sm={6} xs={12}>
          <div className="header__back-link-content" data-test="header-back-to-app">
            {backButtonLink()}
            {userInfo.name && <span className="header__separator mr_5 ml_5">|</span>}
            <UserInfo
              id={'header-user-info'}
              className="header__user-info"
              name={userInfo.name}
              variant="caption"
            />
            {helpIconLink()}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Header
