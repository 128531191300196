import { BrowserRouter as Router } from 'react-router-dom'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'

import StoreProvider from './features/store/Store.context'
import MainLayout from './features/layout/containers/main/Main'
import { TraceProvider } from './tracing'

import 'react-toastify/dist/ReactToastify.css'
import './theme/theme.scss'

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Router>
        <TraceProvider>
          <StoreProvider>
            <MainLayout />
          </StoreProvider>
        </TraceProvider>
      </Router>
    </StyledEngineProvider>
  )
}

export default App
