import { FC } from 'react'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { getMetaValueByTagName, checkActionAvailability } from '../../../../utility/CommonUtility'
import IBreadcrumb from './Breadcrumb.types'

import './Breadcrumb.scss'

const Breadcrumb: FC<IBreadcrumb> = (props) => {
  const { id, className, breadcrumbList } = props
  const navigate = useNavigate()

  const actionAvailability = checkActionAvailability(
    process.env.REACT_APP_ENVIRONMENT || getMetaValueByTagName('REACT_APP_ENVIRONMENT')
  )

  const breadcrumbs = () =>
    breadcrumbList?.map((item: any) =>
      item.link ? (
        <span
          key={item.id}
          className={classnames({
            'action-disabled': !actionAvailability,
          })}
        >
          <Typography
            id="breadcrumb-home-page-link"
            variant={item.id === 1 ? 'h1' : 'body2'}
            onClick={() => navigate(item.link)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') navigate(item.link)
            }}
          >
            Conceptboard&nbsp;
            <div className="breadcrumb__main-item__wrapper">
              Store
              <NavigateNextIcon
                fontSize="large"
                className="breadcrumb__separator"
                id="breadcrumb-separator"
              />
            </div>
          </Typography>
        </span>
      ) : (
        <Typography id="breadcrumb-active-page" key={item.id} color={item?.color}>
          {item.title}
        </Typography>
      )
    )

  return (
    <MuiBreadcrumbs
      id={id}
      className={classnames('breadcrumb', className)}
      separator={''}
      aria-label="breadcrumb"
      data-test="breadcrumb-component"
    >
      {breadcrumbs()}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumb
