import ContentLoader from 'react-content-loader'
import Grid from '@mui/material/Grid'

export const PlanListLoader = () => {
  const planItem = () => (
    <ContentLoader
      speed={2}
      width="100%"
      height="95"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="100" />
    </ContentLoader>
  )
  return (
    <Grid container direction="row" spacing={0.1}>
      <ContentLoader
        speed={2}
        width="100%"
        height="30"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="5" ry="5" width="50" height="20" />
      </ContentLoader>
      <Grid item xs={12} sm={5}>
        {planItem()}
      </Grid>
      <Grid item xs={12} sm={5}>
        {planItem()}
      </Grid>
    </Grid>
  )
}
