import ContentLoader from 'react-content-loader'

export const InvoiceSummaryLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="300"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="15" rx="30" ry="30" width="100%" height="260" />
    </ContentLoader>
  )
}
