import ContentLoader from 'react-content-loader'

export const PaymentInfoLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="45"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="20" ry="20" width="100%" height="45" />
    </ContentLoader>
  )
}
