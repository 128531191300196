import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import './ComingSoonMessage.scss'

const ComingSoonMessage = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <div
        id={'coming-soon-message'}
        className={'coming-soon-message'}
        data-test="coming-soon-message"
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography className="main-text text--center" data-test="coming-soon-main-text">
              {t('comingSoonPage.mainText')}
            </Typography>
            <Typography className="sub-text text--center" data-test="coming-soon-sub-text">
              {t('comingSoonPage.subText')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default ComingSoonMessage
