import i18n from '../translations/i18n'

/**
 * @function dateFormatter
 * @param {*} dateString
 * @returns {formattedDate}
 */
export const dateFormatter = (dateString: string) => {
  const locale = i18n?.language === 'de' ? 'de' : 'en-us'
  const date = new Date(dateString)
  const year = date.toLocaleString('default', { year: 'numeric' })
  const month = date.toLocaleString(locale, { month: 'short' })
  const day = date.toLocaleString('default', { day: '2-digit' })
  const formattedDate = day + ' ' + month + ' ' + year
  return formattedDate
}
