import { FC, useContext } from 'react'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { Formik, Form, FormikHelpers, Field } from 'formik'
import { Button, Title } from 'ccs-react-lib'
import * as Yup from 'yup'
import includes from 'lodash/includes'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

import { StoreContext } from '../../../Store.context'
import { PlanManagerContext } from '../../../containers/planManager/PlanManager.context'
import Breadcrumb from '../../../../shared/components/breadcrumb/Breadcrumb'
import LicenseInfo from '../licenseInfo/LicenseInfo'
import PaymentInfo from '../paymentInfo/PaymentInfo'
import CustomerInfo from '../customerInfo/CustomerInfo'
import InvoiceSummary from '../invoiceSummary/InvoiceSummary'
import { StoreHelper } from '../../../../../helpers/features/StoreHelper'
import { RouteHelper } from '../../../../../helpers/RouteHelper'
import { GeneralHelper } from '../../../../../helpers/GeneralHelper'
import { IBillingFrom } from '../../../containers/planManager/PlanManager.types'
import {
  checkCheckoutSectionAccessibility,
  checkDateIsPast,
  getMetaValueByTagName,
  replaceParameters,
} from '../../../../../utility/CommonUtility'
import { PageLoader } from '../../../../shared/components/contentLoader/page/PageLoader'
import { InvoiceSummaryLoader } from '../../../../shared/components/contentLoader/planManager/invoiceSummary/InvoiceSummaryLoader'
import { ButtonListLoader } from '../../../../shared/components/contentLoader/planManager/button/ButtonLoader'
import { PaymentInfoLoader } from '../../../../shared/components/contentLoader/planManager/paymentInfo/PaymentInfoLoader'
import CustomMessage from '../customMessage/CustomMessage'
import OrderConfirmationModal from '../orderConfirmationModal/OrderConfirmationModal'
import { DetailsSectionLoader } from '../../../../shared/components/contentLoader/planManager/detailsSection/DetailsSectionLoader'

import './PlanManagerBase.scss'

const PlanManagerBase: FC = () => {
  const { t } = useTranslation()
  const {
    managePlanInfoFormData,
    selectablePlanList,
    selectedManagePlanInfo,
    handleFormPlanItemChange,
    handleFormCancelClick,
    expandedSection,
    setExpandedSection,
    numberOfSeatCountInToken,
    isPlanListLoading,
    handleFormInputChange,
    isContractInfoLoading,
    handleCheckoutFormSubmit,
    isFormSubmitting,
    checkoutFormLicenseInfo,
    seatCountInfo,
    openOrderConfirmationModal,
    setOpenOrderConfirmationModal,
    orderConfirmationInfo,
    selectedVatPercentage,
    handleOrderConfirmationSubmit,
    countryList,
    isCheckExtraPlan,
    paymentInfoUpdated,
    isPaymentInfoLoading,
    customBannerMessage,
  } = useContext(PlanManagerContext)
  const { userInfo, openPaymentModal, isCheckoutSectionAccessibility, setOpenPaymentModal } =
    useContext(StoreContext)
  const teamId = userInfo?.teamId
  const overviewPath = replaceParameters(RouteHelper.PATH.OVERVIEW, { teamId })
  const checkoutSectionAccessibility = checkCheckoutSectionAccessibility(
    process.env.REACT_APP_CHECKOUT_SECTION_ACCESSIBILITY ||
      getMetaValueByTagName('REACT_APP_CHECKOUT_SECTION_ACCESSIBILITY'),
    managePlanInfoFormData?.planType
  )
  const extraPlanStatus = isCheckExtraPlan?.(
    managePlanInfoFormData?.planType ?? '',
    checkoutSectionAccessibility
  )

  const planManagerBreadcrumb = [
    {
      id: 1,
      title: GeneralHelper.APP_TITLE,
      link: overviewPath,
      color: 'inherit',
      underline: 'hover',
    },
    {
      id: 2,
      title: isContractInfoLoading ? '...' : t('planManager.titleOnEdit'),
    },
  ]

  const setupPlanBreadcrumb = [
    {
      id: 1,
      title: GeneralHelper.APP_TITLE,
      link: overviewPath,
      color: 'inherit',
      underline: 'hover',
    },
    {
      id: 2,
      title: isContractInfoLoading ? '...' : t('planManager.titleOnAdd'),
    },
  ]

  const customerDetailsManagerBreadcrumb = [
    {
      id: 1,
      title: GeneralHelper.APP_TITLE,
      link: overviewPath,
      color: 'inherit',
      underline: 'hover',
    },
    {
      id: 2,
      title: isContractInfoLoading ? '...' : t('planManager.titleOnEditCustomerInfo'),
    },
  ]

  const handleChange = (panel: any) => {
    let tempExpanded = cloneDeep(expandedSection)
    if (includes(expandedSection, panel)) {
      tempExpanded = tempExpanded?.filter((item) => item !== panel)
      setExpandedSection(tempExpanded)
    } else {
      tempExpanded.push(panel)
      setExpandedSection(tempExpanded)
    }
  }

  const AccordionIcon = () => {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.63 2.852a.797.797 0 0 1 .209-.154.46.46 0 0 1 .238-.061c.1 0 .19.02.269.061.08.041.149.093.209.154L6 6.428l3.445-3.576a.797.797 0 0 1 .21-.154.516.516 0 0 1 .492 0c.069.041.139.093.208.154l.686.694c.07.062.12.128.15.2.04.072.059.16.059.262 0 .103-.02.196-.06.278a.524.524 0 0 1-.149.216L6.447 9.218a.922.922 0 0 1-.193.154.49.49 0 0 1-.254.062c-.1 0-.189-.021-.268-.062a.797.797 0 0 1-.21-.154L.96 4.502a.823.823 0 0 1-.15-.216.629.629 0 0 1-.059-.278c0-.102.02-.19.06-.262a.946.946 0 0 1 .149-.2l.671-.694z"
          fill="#999"
        />
      </svg>
    )
  }

  const validationSchema = Yup.object().shape({
    planId: Yup.string().trim().required(t('planManager.licenseConfiguration.plan.error')),
    planTitle: Yup.string().trim(),
    //TODO: check max count 250 needs to get confirm and check when try to drecrease the limit check with the old value
    numberOfSeats: Yup.number()
      .when('planTitle', {
        is: StoreHelper.PLAN_TYPES.PREMIUM,
        then: Yup.number()
          .min(
            Number(numberOfSeatCountInToken) > StoreHelper.MIN_SEAT_LIMIT
              ? Number(numberOfSeatCountInToken)
              : StoreHelper.MIN_SEAT_LIMIT,
            Number(numberOfSeatCountInToken) > StoreHelper.MIN_SEAT_LIMIT
              ? replaceParameters(
                  t('planManager.licenseConfiguration.numberOfSeat.minValueError'),
                  {
                    numberOfSeatCountInToken,
                  }
                )
              : t('planManager.licenseConfiguration.numberOfSeat.premiumCountError')
          )
          .typeError(t('planManager.common.invalidInput'))
          .integer(t('planManager.common.invalidInput')),
      })
      .when('planTitle', {
        is: StoreHelper.PLAN_TYPES.BUSINESS,
        then: Yup.number()
          .min(
            Number(numberOfSeatCountInToken) > StoreHelper.MAX_SEAT_LIMIT
              ? Number(numberOfSeatCountInToken)
              : StoreHelper.MAX_SEAT_LIMIT,
            Number(numberOfSeatCountInToken) > StoreHelper.MAX_SEAT_LIMIT
              ? replaceParameters(
                  t('planManager.licenseConfiguration.numberOfSeat.minValueError'),
                  {
                    numberOfSeatCountInToken,
                  }
                )
              : t('planManager.licenseConfiguration.numberOfSeat.businessCountError')
          )
          .typeError(t('planManager.common.invalidInput'))
          .integer(t('planManager.common.invalidInput')),
      })
      .typeError(t('planManager.common.invalidInput'))
      .integer(t('planManager.common.invalidInput'))
      .required(t('planManager.licenseConfiguration.numberOfSeat.error')),
    billingPeriod: Yup.string()
      .trim()
      .required(t('planManager.licenseConfiguration.billingPeriod.error')),
    firstName: Yup.string().trim().required(t('planManager.customerConfiguration.firstName.error')),
    lastName: Yup.string().trim().required(t('planManager.customerConfiguration.lastName.error')),
    companyName: Yup.string()
      .trim()
      .required(t('planManager.customerConfiguration.companyName.error')),
    streetNumber: Yup.string()
      .trim()
      .required(t('planManager.customerConfiguration.houseNo.error')),
    street: Yup.string().trim().required(t('planManager.customerConfiguration.street.error')),
    cityNumber: Yup.number()
      .typeError(t('planManager.common.invalidInput'))
      .integer(t('planManager.common.invalidInput'))
      .required(t('planManager.customerConfiguration.postalCode.error')),
    city: Yup.string().trim().required(t('planManager.customerConfiguration.city.error')),
    country: Yup.string().trim().required(t('planManager.customerConfiguration.country.error')),
    invoiceEmail: Yup.array()
      .transform(function (email, emailList) {
        if (this.isType(email) && email !== null) {
          return email
        }
        return emailList ? emailList.split(/[\s,]+/) : []
      })
      .of(Yup.string().trim().email(t('planManager.customerConfiguration.email.notValid')))
      .required(t('planManager.customerConfiguration.email.error'))
      .min(1, t('planManager.customerConfiguration.email.error')),
    invoiceLanguage: Yup.string()
      .trim()
      .required(t('planManager.customerConfiguration.language.error')),
  })

  const initialFormValues = {
    planId: managePlanInfoFormData.planId ?? '',
    planTitle: managePlanInfoFormData.planTitle ?? '',
    numberOfSeats: managePlanInfoFormData.numberOfSeats ?? '',
    billingPeriod: managePlanInfoFormData.billingPeriod ?? '',
    firstName: managePlanInfoFormData.firstName ?? '',
    lastName: managePlanInfoFormData.lastName ?? '',
    companyName: managePlanInfoFormData.companyName ?? '',
    addressLineOne: managePlanInfoFormData.addressLineOne ?? '',
    addressLineTwo: managePlanInfoFormData.addressLineTwo ?? '',
    street: managePlanInfoFormData.street ?? '',
    streetNumber: managePlanInfoFormData.streetNumber ?? '',
    cityNumber: managePlanInfoFormData.cityNumber ?? '',
    city: managePlanInfoFormData.city ?? '',
    country: managePlanInfoFormData.country ?? '',
    euVatId: managePlanInfoFormData.euVatId ?? '',
    invoiceEmail: managePlanInfoFormData.invoiceEmail ?? [],
    invoiceLanguage: managePlanInfoFormData.invoiceLanguage ?? '',
  }

  return (
    <div data-test="plan-manager-base-component" className="plan-manager">
      {(isContractInfoLoading || isFormSubmitting || isPaymentInfoLoading) && <PageLoader />}
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={(values: IBillingFrom, { setSubmitting }: FormikHelpers<IBillingFrom>) => {
          handleCheckoutFormSubmit(values)
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
        render={(formProps) => (
          <Form className="payment-form" data-test="plan-manager-form">
            <Container className="inner-container">
              {/* breadcrumb */}
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Breadcrumb
                    id={'plan-manager-breadcrumb'}
                    className={'plan-manager-breadcrumb'}
                    breadcrumbList={
                      extraPlanStatus
                        ? customerDetailsManagerBreadcrumb
                        : managePlanInfoFormData?.paymentProvider
                        ? planManagerBreadcrumb
                        : setupPlanBreadcrumb
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                className="mt_20 payment-form__scrollable-section"
                spacing={2}
              >
                {isContractInfoLoading ? (
                  <Grid item xs={12} sm={extraPlanStatus ? 12 : 8}>
                    <DetailsSectionLoader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={extraPlanStatus ? 12 : 8}>
                    {!isNil(customBannerMessage) && isCheckoutSectionAccessibility && (
                      <CustomMessage
                        id={
                          managePlanInfoFormData?.isContractLocked
                            ? 'custom-message-for-error'
                            : 'custom-message-for-future'
                        }
                        message={customBannerMessage}
                        className={
                          managePlanInfoFormData?.isContractLocked
                            ? 'custom-message__error'
                            : 'custom-message__primary'
                        }
                      />
                    )}
                    <div className="according">
                      {/* license info */}
                      {!extraPlanStatus && (
                        <>
                          <Accordion
                            className="mb_0"
                            expanded={includes(expandedSection, 'license')}
                            onChange={() => handleChange(`license`)}
                            data-test="plan-manager-accordion"
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Title
                                id={'license-info-title'}
                                content={t('planManager.licenseConfiguration.title')}
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <LicenseInfo
                                id={'license-info'}
                                formProps={formProps}
                                planList={selectablePlanList}
                                handleFormPlanItemChange={handleFormPlanItemChange}
                                contractStatus={managePlanInfoFormData.contractStatus}
                                numberOfSeatCountInToken={numberOfSeatCountInToken}
                                isPlanListLoading={isPlanListLoading}
                                isPreCheckout={managePlanInfoFormData?.cardInfo ? false : true}
                                handleFormInputChange={handleFormInputChange}
                                isLoading={isContractInfoLoading}
                                seatCountInfo={seatCountInfo}
                                contractLockStatus={managePlanInfoFormData?.isContractLocked}
                              />
                            </AccordionDetails>
                          </Accordion>
                          {/* payment info */}
                          <Accordion
                            className="mb_0"
                            expanded={includes(expandedSection, 'payment')}
                            onChange={() => handleChange(`payment`)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                            >
                              <Title
                                id={'payment-info-title'}
                                className="payment-info"
                                content={t('planManager.paymentConfiguration.title')}
                              />
                              {managePlanInfoFormData?.isContractLocked && (
                                <div className="payment__error ml_10">
                                  <ReportProblemIcon className="payment__error-icon" />
                                </div>
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              {isContractInfoLoading ? (
                                <PaymentInfoLoader />
                              ) : (
                                <PaymentInfo
                                  id={'payment-info'}
                                  cardNumber={managePlanInfoFormData?.cardInfo?.last4}
                                  nextBillingDate={managePlanInfoFormData?.nextBillingDate}
                                  cardIsExpired={checkDateIsPast(
                                    `${managePlanInfoFormData?.cardInfo?.expiryYear}-${managePlanInfoFormData?.cardInfo?.expiryMonth}`
                                  )}
                                  openPaymentModal={openPaymentModal}
                                  setOpenPaymentModal={setOpenPaymentModal}
                                />
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </>
                      )}
                      {/* customer info */}
                      <Accordion
                        className="mb_0"
                        expanded={includes(expandedSection, 'customer')}
                        onChange={() => handleChange(`customer`)}
                      >
                        <AccordionSummary
                          expandIcon={<AccordionIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Title
                            id={'customer-info-title'}
                            className={'accordion-title'}
                            content={t('planManager.customerConfiguration.title')}
                          />
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            root: {
                              '&$expanded': {
                                padding: '0',
                              },
                            },
                          }}
                        >
                          <CustomerInfo
                            id={'customer-info'}
                            formProps={formProps}
                            isLoading={isContractInfoLoading}
                            countryList={countryList}
                            handleFormInputChange={handleFormInputChange}
                            contractStatus={managePlanInfoFormData.contractStatus}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                )}
                {/* invoice summary info */}
                {!extraPlanStatus && (
                  <Grid item xs={12} sm={4}>
                    {isContractInfoLoading || isPlanListLoading ? (
                      <InvoiceSummaryLoader />
                    ) : (
                      <InvoiceSummary
                        id="invoice-summary"
                        discount={Number(managePlanInfoFormData?.discount)}
                        vatPercentage={selectedVatPercentage}
                        summaryInfo={{
                          price: selectedManagePlanInfo.price,
                          numberOfSeats:
                            Number(formProps.values.numberOfSeats) - Number(seatCountInfo.freeSeat),
                          billingPeriod: Number(formProps.values.billingPeriod),
                        }}
                        currency={managePlanInfoFormData?.currency}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Container>
            <div className="payment-form__fixed-section">
              <Container className="payment-form__button-container">
                <Field
                  id="contract-payment-provider"
                  type="hidden"
                  name="contractPaymentProvider"
                  value={managePlanInfoFormData?.paymentProvider}
                />
                <div className="payment-form__button-wrapper">
                  {isContractInfoLoading ? (
                    <ButtonListLoader />
                  ) : (
                    <>
                      <Button
                        id={'add-payment-method'}
                        type="submit"
                        className="mt_20 mb_20"
                        data-test="add-payment-method"
                        disabled={
                          (!paymentInfoUpdated ||
                            managePlanInfoFormData?.contractStatus !==
                              StoreHelper.CONTRACT_STATUS.TRIAL) &&
                          (!formProps.values.planId ||
                            (!extraPlanStatus && !formProps.values.planTitle) ||
                            isPlanListLoading ||
                            (!formProps.dirty &&
                              checkoutFormLicenseInfo.planId === formProps.values.planId))
                        }
                        tabIndex={0}
                        content={t('planManager.formButton.update')}
                        size="large"
                      />

                      <Button
                        id={'cancel'}
                        className="mt_20 mb_20 ml_20"
                        onClick={() => handleFormCancelClick()}
                        data-test="cancel-add-payment-method"
                        tabIndex={0}
                        content={t('planManager.formButton.cancel')}
                        variant="tertiary"
                        size="large"
                      />
                    </>
                  )}
                </div>
              </Container>
            </div>
          </Form>
        )}
      />
      {openOrderConfirmationModal && (
        <OrderConfirmationModal
          id={'order-confirmation-modal'}
          open={openOrderConfirmationModal}
          onClose={setOpenOrderConfirmationModal}
          orderInfo={orderConfirmationInfo}
          currency={managePlanInfoFormData?.currency}
          contractStatus={managePlanInfoFormData.contractStatus}
          onSubmit={handleOrderConfirmationSubmit}
          callbackValue={orderConfirmationInfo?.orderId}
        />
      )}
    </div>
  )
}

export default PlanManagerBase
