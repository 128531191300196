/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { GeneralHelper } from '../helpers/GeneralHelper'
import { getCurrentEnvironment, getMetaValueByTagName, decodeJwt } from './CommonUtility'
import StorageUtitlity from '../utility/StorageUtility'
import { LocalStorageHelper } from '../helpers/LocalStorageHelper'

declare let _paq: any

/**
 * Matomo analytics event category
 */
export const enum MotomoEventCategory {
  USER_ENGAGEMENT = 'user engagement',
}

/**
 * Matomo analytics event action
 */
export const enum MotomoEventAction {
  UPDATE_PAYMENT_INFO = 'update payment info',
  UPDATE_CUSTOMER_INFO = 'update customer info',
  UPDATE_PLAN_INFO = 'update plan info',
  DOWNLOAD_INVOICE = 'download invoice',
}

export const enum MotomoMethod {
  TRACK_EVENT = 'trackEvent',
  TRACK_PAGE_VIEW = 'trackPageView',
}

export const getCompanyAndUserID = () => {
  const token = StorageUtitlity.getSingleData(LocalStorageHelper.ACCESS_TOKEN)
  let companyId
  let userId
  if (!isNil(token)) {
    const tokenInfo = decodeJwt(token)
    companyId = tokenInfo && tokenInfo['https://conceptboard.com/ns/oauth#claim_globalcompanyid']
    userId = tokenInfo && tokenInfo['sub']
  }
  return { companyId: companyId, userId: userId }
}

export const setMatomoAnalytics = (logInfo: any) => {
  const matomoSiteId = process.env.REACT_APP_MATOMO_SITE_ID
    ? process.env.REACT_APP_MATOMO_SITE_ID
    : getMetaValueByTagName('REACT_APP_MATOMO_SITE_ID')

  const matomoUrl = process.env.REACT_APP_MATOMO_URL
    ? process.env.REACT_APP_MATOMO_URL
    : getMetaValueByTagName('REACT_APP_MATOMO_URL')

  const appInstance = process.env.REACT_APP_MATOMO_INSTANCE
    ? process.env.REACT_APP_MATOMO_INSTANCE
    : getMetaValueByTagName('REACT_APP_MATOMO_INSTANCE')
  if (
    getCurrentEnvironment() !== GeneralHelper.APP_ENV.LOCAL &&
    !isEmpty(matomoSiteId) &&
    !isEmpty(matomoUrl)
  ) {
    if (!isNil(getCompanyAndUserID()?.userId)) {
      _paq.push(['setCustomDimension', 5, getCompanyAndUserID()?.userId])
      _paq.push(['setUserId', getCompanyAndUserID()?.userId])
    }
    if (!isNil(getCompanyAndUserID()?.companyId)) {
      _paq.push(['setCustomDimension', 6, getCompanyAndUserID()?.companyId])
    }
    if (!isNil(appInstance)) {
      _paq.push(['setCustomDimension', 7, appInstance])
    }
    if (logInfo?.type === MotomoMethod.TRACK_EVENT) {
      _paq.push(['trackEvent', logInfo?.category, logInfo?.action, logInfo?.value])
    } else {
      _paq.push(['setCustomUrl', logInfo.href])
      _paq.push(['setDocumentTitle', logInfo.documentTitle])
      _paq.push(['trackPageView'])
    }
  }
}
