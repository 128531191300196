import { FC } from 'react'
import classnames from 'classnames'
import { Modal as MuiModal } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import isNil from 'lodash/isNil'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import IModal from './Modal.types'

import './Modal.scss'

const Modal: FC<IModal> = (props) => {
  const { id, className, open = false, size, onClose, title, titleClassName, children } = props

  const modalSize = !isNil(size) ? `modal--${size}` : null

  return (
    <MuiModal
      id={id}
      className={classnames('modal', className, modalSize)}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-test="modal-component"
    >
      <Box>
        <div className="modal__header">
          {title && (
            <Typography variant="h5" className={titleClassName} data-test="modal-title">
              {title}
            </Typography>
          )}
          <IconButton
            aria-label="close"
            className="modal__close-icon"
            onClick={onClose}
            tabIndex={0}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div data-test="modal-content" className="modal__content">
          {children}
        </div>
      </Box>
    </MuiModal>
  )
}
export default Modal
