import { FC } from 'react'

import PlanManagerProvider from '../../containers/planManager/PlanManager.context'
import PlanManagerBase from '../../components/planManager/planManagerBase/PlanManagerBase'

const PlanManager: FC = () => {
  return (
    <PlanManagerProvider>
      <PlanManagerBase data-test="plan-manager-wrapper" />
    </PlanManagerProvider>
  )
}

export default PlanManager
