import { createContext, useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import size from 'lodash/size'
import uniq from 'lodash/uniq'

import { StoreHelper } from '../../../../helpers/features/StoreHelper'
import { RouteHelper } from '../../../../helpers/RouteHelper'
import { IPlanManagerContext, ILocationState, IManagePlanInfo, ICountry } from './PlanManager.types'
import { IPlan } from '../../components/overview/plan/planList/PlanList.types'
import {
  getContractInfo,
  getPlanListByPeriod,
  updateCustomerInfo,
  updateSeatCountInfo,
  updatePlanInfo,
  confirmOrder,
  getCountryList,
  createNewContract,
  unlockContract,
} from '../../../../api/store/StoreApi'
import { StoreContext } from '../../Store.context'
import {
  checkCheckoutSectionAccessibility,
  decodeJwt,
  getMetaValueByTagName,
  replaceParameters,
} from '../../../../utility/CommonUtility'
import { LocalStorageHelper } from '../../../../helpers/LocalStorageHelper'
import StorageUtitlity from '../../../../utility/StorageUtility'
import { showAlert } from '../../../../utility/ToasterUtility'
import { MotomoMethod, setMatomoAnalytics } from '../../../../utility/MatomoAnalytics'

export const PlanManagerContext = createContext<IPlanManagerContext>({} as IPlanManagerContext)

const emptySelectedPlanInfo = {
  id: '',
  price: 0,
  title: '',
  componentId: '',
}

const emptySeatCountInfo = {
  regularSeat: 0,
  freeSeat: 0,
}

/**
 * get billing period text
 * @param billingPeriod
 * @returns billin period text
 */
export const getBillingPeriodText = (billingPeriod: number): string => {
  switch (billingPeriod) {
    case 12:
      return t('planManager.licenseConfiguration.billingPeriod.options.annually')
    case 6:
      return t('planManager.licenseConfiguration.billingPeriod.options.semiAnnually')
    case 3:
      return t('planManager.licenseConfiguration.billingPeriod.options.quarterly')
    case 1:
      return t('planManager.licenseConfiguration.billingPeriod.options.monthly')
    default:
      return ''
  }
}

const PlanManagerProvider = (props: { children: any }) => {
  const { children } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { section } = useParams()
  const locationState = location.state as ILocationState
  const [isPlanListLoading, setIsPlanListLoading] = useState(false)
  const [selectablePlanList, setSelectablePlanList] = useState<IPlan[]>([])
  const [managePlanInfoFormData, setManagePlanInfoFormData] = useState<IManagePlanInfo>({})
  const [selectedManagePlanInfo, setSelectedManagePlanInfo] = useState(emptySelectedPlanInfo)
  const [expandedSection, setExpandedSection] = useState([''])
  const [numberOfSeatCountInToken, setNumberOfSeatCountInToken] = useState(0)
  const [isContractInfoLoading, setIsContractInfoLoading] = useState(true)
  const [isPaymentInfoLoading, setIsPaymentInfoLoading] = useState(false)
  const [contractInfo, setContractInfo] = useState({})
  const {
    userInfo,
    updatePaymentInfoSuccess,
    setContractId,
    setUpdatePaymentInfoSuccess,
    setIsContentLoading,
    isCheckoutSectionAccessibility,
    setIsCheckoutSectionAccessibility,
  } = useContext(StoreContext)
  const [isFormSubmitting, setFormSubmitting] = useState(false)
  const teamId = userInfo?.teamId
  const overviewPath = replaceParameters(RouteHelper.PATH.OVERVIEW, { teamId })
  const [checkoutFormUserInfo, setCheckoutFormUserInfo] = useState<IManagePlanInfo>({})
  const [checkoutFormLicenseInfo, setCheckoutFormLicenseInfo] = useState<IManagePlanInfo>({})
  const [selectedPlanVariant, setSelectedPlanVariant] = useState('')
  const [seatCountInfo, setSeatCountInfo] = useState(emptySeatCountInfo)
  const [openOrderConfirmationModal, setOpenOrderConfirmationModal] = useState(false)
  const [orderConfirmationInfo, setOrderConfirmationInfo] = useState<IManagePlanInfo>({})
  const [selectedVatPercentage, setSelectedVatPercentage] = useState(0)
  const [countryList, setCountryList] = useState<ICountry[]>([])
  const [tokenLocale, setTokenLocale] = useState('en-US')
  const [paymentInfoUpdated, setPaymentInfoUpdated] = useState(false)
  const [futureComponentSubscription, setFutureComponentSubscription] = useState<any>()
  const [customBannerMessage, setCustomBannerMessage] = useState(null)

  useEffect(() => {
    const token = StorageUtitlity.getSingleData(LocalStorageHelper.ACCESS_TOKEN)
    if (token) {
      const tokenInfo = decodeJwt(token)
      setNumberOfSeatCountInToken(
        tokenInfo['https://conceptboard.com/ns/oauth#claim_numberofusedseats'] || 0
      )
      setTokenLocale(tokenInfo?.locale)
    }
    setIsPlanListLoading(true)
    fetchCountryList()
    fetchContractInfo(false)
    const pageInfo = {
      type: MotomoMethod.TRACK_PAGE_VIEW,
      documentTitle: t('matomoLog.pageTitle.checkoutSection'),
      href: window.location.href,
    }
    setMatomoAnalytics(pageInfo)
    !!section && handleExpandSection(section)
  }, [])

  useEffect(() => {
    getPlanInfoById(managePlanInfoFormData.planId)
  }, [managePlanInfoFormData])

  useEffect(() => {
    if (selectablePlanList.length > 0) {
      getPlanInfoById(managePlanInfoFormData.planId)
    }
  }, [selectablePlanList])

  useEffect(() => {
    if (!isEmpty(contractInfo)) {
      setCheckoutInformations(contractInfo)
    }
  }, [contractInfo])

  useEffect(() => {
    if (!isEmpty(selectedManagePlanInfo.id)) {
      setManagePlanInfoFormData({
        ...managePlanInfoFormData,
        planTitle: selectedManagePlanInfo.title,
      })
    }
  }, [selectedManagePlanInfo])

  useEffect(() => {
    if (!isEmpty(updatePaymentInfoSuccess)) {
      fetchContractInfo(true)
      setUpdatePaymentInfoSuccess({})
      setIsContentLoading(false)
      if (
        isEmpty(managePlanInfoFormData?.paymentProvider) &&
        managePlanInfoFormData?.contractStatus === StoreHelper.CONTRACT_STATUS.TRIAL
      ) {
        createNewContractPahse(contractInfo)
      }

      if (managePlanInfoFormData?.isContractLocked) {
        unlockCurrentContract(contractInfo)
      }
    }
  }, [updatePaymentInfoSuccess])

  useEffect(() => {
    if (isEmpty(checkoutFormLicenseInfo?.componentId) && !isEmpty(selectablePlanList)) {
      const planInfo = selectablePlanList.find((plan) => plan.id === checkoutFormLicenseInfo.planId)
      setCheckoutFormLicenseInfo({ ...checkoutFormLicenseInfo, componentId: planInfo?.componentId })
    }
  }, [checkoutFormLicenseInfo, selectablePlanList])

  useEffect(() => {
    if (!isEmpty(orderConfirmationInfo)) {
      setOpenOrderConfirmationModal?.(true)
    }
  }, [orderConfirmationInfo])

  useEffect(() => {
    if (!isEmpty(contractInfo) && size(countryList) > 0) {
      const contractResponse: any = contractInfo
      if (contractResponse?.customer?.address?.country) {
        setCountryVatPercentage(countryList, contractResponse?.customer?.address?.country)
      }
    }
  }, [countryList, contractInfo])

  /**
   * get paln info using plan id
   * @param planId
   */
  const getPlanInfoById = (planId: string) => {
    if (selectablePlanList) {
      const selectedPlanInfo = selectablePlanList.find((plan) => plan.id === planId)
      if (selectedPlanInfo) {
        setSelectedManagePlanInfo(selectedPlanInfo)
        setSelectedPlanVariant(selectedPlanInfo?.planVariantId ?? '')
        // TODO: in MTA-169
        // setManagePlanInfoFormData({
        //   ...managePlanInfoFormData,
        //   planTitle: selectedPlanInfo.title,
        // })
      }
    }
  }

  /**
   * handle form element plan item change
   * @param formProps
   * @param name
   * @param value
   */
  const handleFormPlanItemChange = (formProps: any, data: any) => {
    data?.forEach((item: any) => {
      formProps.setFieldValue(item.name, item.value)
      if (item.name == 'planId') {
        getPlanInfoById(item.value)
        setManagePlanInfoFormData({
          ...managePlanInfoFormData,
          ...formProps.values,
          planId: item.value,
        })
      } else if (item.name == 'planTitle') {
        setManagePlanInfoFormData({
          ...managePlanInfoFormData,
          planTitle: item.value,
        })
      }
    })
  }

  const handleFormInputChange = (formProps: any, data: any) => {
    formProps.setFieldValue(data.name, data.value)
    // fetch plan list with the new billing period
    if (data.name === 'billingPeriod') {
      const planParams = {
        billingPeriod: data.value ? `${data.value}M` : '12M',
        currency: managePlanInfoFormData?.currency,
      }
      fetchPlanListByPeriod(planParams)
    }
    if (data.name === 'numberOfSeats') {
      const regularSeatCount = Number(data.value) - Number(seatCountInfo.freeSeat)
      setSeatCountInfo({ ...seatCountInfo, regularSeat: regularSeatCount })
    }
    if (data.name === 'country') {
      setCountryVatPercentage(countryList, data.value)
    }
  }

  /**
   * handle form cancel button click
   */
  const handleFormCancelClick = () => {
    navigate(overviewPath)
  }

  /**
   * handle expand section when page load
   * @param section
   */
  const handleExpandSection = (section: string) => {
    if (section === 'details') {
      setExpandedSection(['customer'])
    } else if (section === 'plan') {
      setExpandedSection(['license'])
    } else {
      setExpandedSection(['payment'])
    }
  }

  /**
   * set country related vat percentage
   * @param countryList
   * @param countryCode
   */
  const setCountryVatPercentage = (countryList: ICountry[], countryCode: string) => {
    const selectedCountryInfo = countryList?.find((country) => country?.code === countryCode)
    setSelectedVatPercentage(selectedCountryInfo?.vatPercentage ?? 0)
  }

  /**
   * set checkout information into the from state
   * @param response
   */
  const setCheckoutInformations = (response: any, planLoading = true) => {
    const checkoutSectionAccessibility = checkCheckoutSectionAccessibility(
      process.env.REACT_APP_CHECKOUT_SECTION_ACCESSIBILITY ||
        getMetaValueByTagName('REACT_APP_CHECKOUT_SECTION_ACCESSIBILITY'),
      response?.currentPlan?.name?._c
    )
    setIsCheckoutSectionAccessibility?.(checkoutSectionAccessibility)
    const planParams = {
      billingPeriod: response?.currentPlanVariant?.billingPeriod?.quantity
        ? `${response.currentPlanVariant.billingPeriod.quantity}M`
        : '12M',
      currency: response?.contract?.currency,
    }
    let additionalEmailAddresses: any = []
    additionalEmailAddresses = response?.customer?.additionalEmailAddresses?.map((email: any) => {
      return email.emailAddress
    })
    const invoicePrimaryEmail = response?.customer?.emailAddress
      ? response?.customer?.emailAddress
      : userInfo?.email

    additionalEmailAddresses.splice(0, 0, invoicePrimaryEmail)

    const invoiceLanguage =
      response?.customer?.locale === 'en' || !response?.customer?.locale
        ? tokenLocale
        : response?.customer?.locale
    const planType = response?.currentPlan?.name?._c
    if (planLoading && !isCheckExtraPlan(planType, checkoutSectionAccessibility)) {
      fetchPlanListByPeriod(planParams)
    } else {
      setIsPlanListLoading(false)
    }

    //free seat info
    const freeSeatCountInfo = response?.currentComponentSubscriptions?.find(
      (sub: any) => sub.freeSeats === true
    )
    //regular seat info
    const regularSeatCountInfo = response?.currentComponentSubscriptions?.find(
      (sub: any) => sub.freeSeats === false
    )
    const totalSeatCount =
      (freeSeatCountInfo?.quantity ? freeSeatCountInfo?.quantity : 0) +
      (regularSeatCountInfo?.quantity ? regularSeatCountInfo?.quantity : 0)

    setSeatCountInfo({
      regularSeat: regularSeatCountInfo?.quantity ? Number(regularSeatCountInfo?.quantity) : 0,
      freeSeat: freeSeatCountInfo?.quantity ? Number(freeSeatCountInfo?.quantity) : 0,
    })

    setManagePlanInfoFormData({
      ...managePlanInfoFormData,
      planId: response?.contract?.currentPhase?.planId,
      ...(!isNil(locationState) && {
        planId: locationState?.planId,
        // planTitle: locationState?.planTitle ? ' ': '',
      }),

      numberOfSeats:
        numberOfSeatCountInToken > totalSeatCount ? numberOfSeatCountInToken : totalSeatCount,
      billingPeriod:
        !isNil(response?.currentPlanVariant?.billingPeriod?.quantity) &&
        !isCheckExtraPlan(planType, checkoutSectionAccessibility)
          ? response?.currentPlanVariant?.billingPeriod?.quantity
          : '12',
      firstName: response?.customer?.firstName,
      lastName: response?.customer?.lastName,
      companyName: response?.customer?.companyName,
      addressLineOne: response?.customer?.address?.addressLine1,
      addressLineTwo: response?.customer?.address?.addressLine2,
      street: response?.customer?.address?.street,
      streetNumber: response?.customer?.address?.houseNumber,
      cityNumber: response?.customer?.address?.postalCode,
      city: response?.customer?.address?.city,
      country: response?.customer?.address?.country,
      euVatId: response?.customer?.vatId,
      invoiceEmail: additionalEmailAddresses,
      invoiceLanguage: invoiceLanguage,
      contractStatus: response?.contract?.lifecycleStatus,
      ...(!isNil(response?.contract?.paymentBearer) && {
        cardInfo: response?.contract?.paymentBearer,
      }),
      nextBillingDate: response?.contract?.nextBillingDate,
      currency: response?.contract?.currency,
      discount: response?.contract?.discount || 0,
      type: response?.contract?.currentPhase?.type,
      startDate: response?.contract?.startDate,
      trialEndDate: response?.contract?.trialEndDate,
      paymentProvider: response?.contract?.paymentProvider,
      planType: response?.currentPlan?.name?._c,
      isContractLocked: !isNil(response?.contract?.customFields?.locked_contract)
        ? response?.contract?.customFields?.locked_contract === 'true'
          ? true
          : false
        : false,
    })

    //user obj
    const userObj = {
      billwerkId: response?.customer?.id,
      firstName: response?.customer?.firstName,
      lastName: response?.customer?.lastName,
      address: {
        addressLine1: response?.customer?.address?.addressLine1,
        addressLine2: response?.customer?.address?.addressLine2,
        city: response?.customer?.address?.city,
        country: response?.customer?.address?.country,
        streetNumber: response?.customer?.address?.houseNumber,
        street: response?.customer?.address?.street,
        postalCode: response?.customer?.address?.postalCode,
      },
      companyName: response?.customer?.companyName,
      euVatId: response?.customer?.vatId ?? '',
      additionalEmailAddresses: additionalEmailAddresses.slice(1),
      invoiceLanguage: invoiceLanguage,
      invoiceEmail: invoicePrimaryEmail,
      debitorAccount: response?.customer?.debitorAccount,
    }
    setCheckoutFormUserInfo(userObj)

    //license obj
    const licenseObj = {
      planId: response?.contract?.currentPhase?.planId,
      planVariantId: response?.contract?.currentPhase?.planVariantId,
      numberOfSeats:
        numberOfSeatCountInToken > totalSeatCount
          ? numberOfSeatCountInToken
          : regularSeatCountInfo?.quantity,
      numberOfFreeSeats: freeSeatCountInfo?.quantity ? freeSeatCountInfo?.quantity : 0,
      contractId: response?.contract?.id,
      ...(!isNil(freeSeatCountInfo?.componentId) && {
        freeSeatComponentId: freeSeatCountInfo?.componentId,
      }),
    }
    setCheckoutFormLicenseInfo(licenseObj)
  }

  /**
   * fetch plan list by period
   * @param params
   */
  const fetchPlanListByPeriod = async (params: any) => {
    try {
      setIsPlanListLoading(true)
      const { items } = await getPlanListByPeriod(params, userInfo?.id)
      const billingPeriod = params?.billingPeriod.slice(0, -1)
      if (items.length > 0) {
        items.map((planData: any) => {
          if (planData.title === StoreHelper.PLAN_TYPES.PREMIUM) {
            if (isNil(planData.price)) {
              planData.price = 0
            }
            planData.id = planData.planId
            planData.description = replaceParameters(
              t('planManager.licenseConfiguration.planInfo.premiumInfoText'),
              { billingPeriod: getBillingPeriodText(Number(billingPeriod)) }
            )
            return planData
          } else {
            if (isNil(planData.price)) {
              planData.price = 0
            }
            planData.id = planData.planId
            planData.description = replaceParameters(
              t('planManager.licenseConfiguration.planInfo.businessInfoText'),
              { billingPeriod: getBillingPeriodText(Number(billingPeriod)) }
            )
            return planData
          }
        })
        setSelectablePlanList(items)
      }
      setIsPlanListLoading(false)
    } catch (error) {
      setSelectablePlanList([])
      setIsPlanListLoading(false)
    }
  }

  /**
   * fetch current contract info
   * @param isUpdatePaymentInfo
   */
  const fetchContractInfo = async (
    isUpdatePaymentInfo = false,
    isUpdateContractLockStatus = false
  ) => {
    try {
      if (!isUpdatePaymentInfo && !isUpdateContractLockStatus) {
        setIsContractInfoLoading(true)
      } else if (isUpdateContractLockStatus) {
        setIsPaymentInfoLoading(true)
      } else {
        setIsPaymentInfoLoading(true)
      }
      const data = await getContractInfo(userInfo?.id)

      if (isUpdatePaymentInfo) {
        const updatedContractInfo: any = data
        setManagePlanInfoFormData({
          ...managePlanInfoFormData,
          ...(!isNil(updatedContractInfo?.contract?.paymentBearer) && {
            cardInfo: updatedContractInfo?.contract?.paymentBearer,
          }),
          nextBillingDate: updatedContractInfo?.contract?.nextBillingDate,
          paymentProvider: updatedContractInfo?.contract?.paymentProvider,
        })
      } else if (isUpdateContractLockStatus) {
        const updatedContractInfo: any = data
        setCustomBannerMessage(updatedContractInfo?.customerMessage)
        setManagePlanInfoFormData({
          ...managePlanInfoFormData,
          ...(!isNil(updatedContractInfo?.contract?.paymentBearer) && {
            cardInfo: updatedContractInfo?.contract?.paymentBearer,
          }),
          nextBillingDate: updatedContractInfo?.contract?.nextBillingDate,
          paymentProvider: updatedContractInfo?.contract?.paymentProvider,
          isContractLocked:
            updatedContractInfo?.contract?.customFields?.locked_contract === 'true' ? true : false,
        })
      } else {
        setContractInfo(data)
        setCustomBannerMessage(data?.customerMessage)
      }
      if (data?.contract?.id) {
        setContractId?.(data.contract.id)
      }
      setIsContractInfoLoading(false)
      setIsPaymentInfoLoading(false)
    } catch (error) {
      setContractInfo({})
      setIsContractInfoLoading(false)
      setIsPaymentInfoLoading(false)
    }
  }

  /**
   * handle form submit event
   * @param formData
   */
  const handleCheckoutFormSubmit = async (formData: any) => {
    setFormSubmitting(true)
    // user obj
    const updatedUserObj = {
      billwerkId: checkoutFormUserInfo?.billwerkId,
      firstName: formData?.firstName.trim(),
      lastName: formData?.lastName.trim(),
      address: {
        addressLine1: formData.addressLineOne.trim(),
        addressLine2: formData?.addressLineTwo.trim(),
        city: formData?.city.trim(),
        country: formData?.country,
        streetNumber: formData?.streetNumber.trim(),
        street: formData?.street.trim(),
        postalCode: formData?.cityNumber.trim(),
      },
      companyName: formData?.companyName.trim(),
      euVatId: formData?.euVatId.trim(),
      invoiceEmail: isArray(formData?.invoiceEmail)
        ? formData?.invoiceEmail[0]
        : formData?.invoiceEmail
            .split(',')
            ?.map((email: string) => email.trim())
            .filter(Boolean)[0],
      additionalEmailAddresses: isArray(formData?.invoiceEmail)
        ? formData?.invoiceEmail.slice(1)
        : formData?.invoiceEmail
            .split(',')
            ?.map((email: string) => email.trim())
            .filter(Boolean)
            .slice(1),
      invoiceLanguage: formData?.invoiceLanguage,
      debitorAccount: checkoutFormUserInfo?.debitorAccount,
    }

    const updatedLicenseObj = {
      planId: formData?.planId,
      numberOfSeats: Number(seatCountInfo.regularSeat),
      numberOfFreeSeats: Number(seatCountInfo.freeSeat) > 0 ? Number(seatCountInfo.freeSeat) : null,
      planVariantId: selectedPlanVariant,
    }
    let userUpdateResponse = {}
    let planInfoUpdateResponse = {}
    let seatInfoUpdateResponse = {}

    if (!isEqual(updatedUserObj, checkoutFormUserInfo)) {
      // call user update API
      userUpdateResponse = await updateCustomerInfo(updatedUserObj, userInfo?.id)
      // TODO: MTA-297 task
      // if (isNil(userUpdateResponse)) {
      //   TODO: get error message from the API
      //   showAlert('error', t('planManager.formSubmission.error'))
      // }
    }
    if (
      !isCheckExtraPlan(managePlanInfoFormData?.planType, isCheckoutSectionAccessibility) &&
      (managePlanInfoFormData?.contractStatus === StoreHelper.CONTRACT_STATUS.TRIAL ||
        managePlanInfoFormData?.contractStatus === StoreHelper.CONTRACT_STATUS.ACTIVE)
    ) {
      const contractStatus =
        managePlanInfoFormData?.contractStatus === StoreHelper.CONTRACT_STATUS.TRIAL
          ? 'trial'
          : 'active'
      const contractResponse: any = contractInfo

      const oldComponentsArray: any = []

      if (isNil(updatedLicenseObj.numberOfFreeSeats)) {
        oldComponentsArray.push(checkoutFormLicenseInfo?.componentId)
      } else {
        oldComponentsArray.push(
          checkoutFormLicenseInfo?.componentId,
          checkoutFormLicenseInfo?.freeSeatComponentId
        )
      }

      const futureComponentSubscriptionInfo = !isNil(futureComponentSubscription)
        ? futureComponentSubscription
        : !isNil(contractResponse?.futureComponentSubscription)
        ? contractResponse?.futureComponentSubscription
        : {}

      if (!isNil(futureComponentSubscriptionInfo) || !isEmpty(futureComponentSubscriptionInfo)) {
        size(futureComponentSubscriptionInfo) > 0 &&
          futureComponentSubscriptionInfo?.map((info: any) => {
            oldComponentsArray.push(info.componentId)
          })
      }
      if (
        (checkoutFormLicenseInfo.numberOfSeats !== updatedLicenseObj.numberOfSeats &&
          checkoutFormLicenseInfo.planId !== updatedLicenseObj.planId) ||
        (checkoutFormLicenseInfo.numberOfSeats === updatedLicenseObj.numberOfSeats &&
          checkoutFormLicenseInfo.planId !== updatedLicenseObj.planId) ||
        checkoutFormLicenseInfo.planVariantId !== updatedLicenseObj.planVariantId
      ) {
        // call seat and plan info update API
        const licenseBody = {
          componentId: selectedManagePlanInfo?.componentId,
          contractId: checkoutFormLicenseInfo?.contractId,
          numberOfSeats: updatedLicenseObj.numberOfSeats,
          numberOfFreeSeats: updatedLicenseObj.numberOfFreeSeats,
          oldComponents: uniq(oldComponentsArray),
          planVariantId: updatedLicenseObj.planVariantId,
        }
        const licenseBodyForUpdateContract = {
          ...licenseBody,
          hasAFutureSubscription:
            !isNil(contractResponse?.futureComponentSubscription) ||
            !isEmpty(futureComponentSubscription)
              ? true
              : false,
          currentContractStartDate: managePlanInfoFormData?.startDate,
        }
        planInfoUpdateResponse = await updatePlanInfo(
          licenseBodyForUpdateContract,
          userInfo?.id,
          contractStatus
        )
        if (isNil(planInfoUpdateResponse)) {
          showAlert('error', t('planManager.formSubmission.error'))
        }
      } else if (checkoutFormLicenseInfo.numberOfSeats !== updatedLicenseObj.numberOfSeats) {
        // call seat update API
        const licenseBody = {
          componentId: selectedManagePlanInfo?.componentId,
          contractId: checkoutFormLicenseInfo?.contractId,
          numberOfSeats: updatedLicenseObj.numberOfSeats,
          numberOfFreeSeats: updatedLicenseObj.numberOfFreeSeats,
          oldComponents: uniq(oldComponentsArray),
        }
        const licenseBodyUpdateContract = {
          ...licenseBody,
          hasAFutureSubscription:
            !isNil(contractResponse?.futureComponentSubscription) ||
            !isEmpty(futureComponentSubscription)
              ? true
              : false,
        }

        seatInfoUpdateResponse = await updateSeatCountInfo(
          licenseBodyUpdateContract,
          userInfo?.id,
          contractStatus
        )
        if (isNil(seatInfoUpdateResponse)) {
          showAlert('error', t('planManager.formSubmission.error'))
        }
      }
    }
    const summaryInfo = {
      price: selectedManagePlanInfo.price,
      numberOfSeats: Number(seatCountInfo.regularSeat),
      billingPeriod: Number(formData.billingPeriod),
      discount: Number(managePlanInfoFormData?.discount),
    }

    const totalNet =
      summaryInfo.billingPeriod && summaryInfo.price > 0 && summaryInfo.numberOfSeats > 0
        ? summaryInfo.price * Number(summaryInfo.billingPeriod) * summaryInfo.numberOfSeats
        : 0
    const totalVat = Number(
      (totalNet > 0
        ? ((totalNet - summaryInfo.discount) * selectedVatPercentage) / 100
        : 0
      ).toFixed(2)
    )
    const totalGross = Number(totalNet) - Number(summaryInfo.discount) + totalVat

    if (
      !isNil(userUpdateResponse) &&
      !isNil(planInfoUpdateResponse) &&
      !isNil(seatInfoUpdateResponse)
    ) {
      if (
        !isEmpty(userUpdateResponse) &&
        isEmpty(planInfoUpdateResponse) &&
        isEmpty(seatInfoUpdateResponse)
      ) {
        if (!paymentInfoUpdated) {
          showAlert('success', t('planManager.formSubmission.successUpdate'))
          navigate(overviewPath)
        }
      } else {
        const commonResponse: any = isEmpty(planInfoUpdateResponse)
          ? seatInfoUpdateResponse
          : planInfoUpdateResponse
        if (!isNil(commonResponse?.confirmationInfo)) {
          if (managePlanInfoFormData?.contractStatus === StoreHelper.CONTRACT_STATUS.TRIAL) {
            const trialOrderConfirmationInfo = {
              changeDate: commonResponse?.confirmationInfo?.changeDate,
              items: [
                {
                  description: `${selectedManagePlanInfo?.title}  ${t(
                    'orderConfirmationModal.seatInfoText'
                  )} : ${getBillingPeriodText(Number(summaryInfo.billingPeriod))}`,
                  pricePerUnit: summaryInfo?.price,
                  quantity: summaryInfo.numberOfSeats,
                  scaleAmount: summaryInfo?.numberOfSeats,
                  totalGross: totalGross,
                  totalNet: totalNet,
                  vatPercentage: selectedVatPercentage,
                },
              ],
              nextInvoiceDate: managePlanInfoFormData?.nextBillingDate,
              nextInvoiceTotal: totalGross,
              orderId: commonResponse?.confirmationInfo?.orderId,
              totalGross: totalGross,
              total: totalNet,
              totalVat: totalVat,
              trialEnds: commonResponse?.confirmationInfo?.trialEnds,
            }
            setOrderConfirmationInfo(trialOrderConfirmationInfo)
          } else {
            setOrderConfirmationInfo(commonResponse?.confirmationInfo)
          }
        }
        setFormSubmitting(false)
      }
    } else {
      setFormSubmitting(false)
    }
  }

  /**
   * update current order confirmation
   * @param orderId
   */
  const handleOrderConfirmationSubmit = async (orderId: string) => {
    try {
      setFormSubmitting(true)
      const data = await confirmOrder(orderId, userInfo?.id)
      if (data?.status === 200) {
        if (managePlanInfoFormData?.cardInfo) {
          showAlert('success', t('planManager.formSubmission.successUpdate'))
        } else {
          showAlert('success', t('planManager.formSubmission.successUpdate')) //TODO: successPurchase
        }
        navigate(overviewPath)
        setFormSubmitting(false)
      } else {
        setFormSubmitting(false)
        showAlert('error', t('planManager.formSubmission.error'))
      }
    } catch (error) {
      setFormSubmitting(false)
    }
  }

  /**
   * fetch country list
   */
  const fetchCountryList = async () => {
    try {
      const data = await getCountryList(userInfo?.id)
      if (size(data) > 0) {
        const locale = StorageUtitlity.getSingleData(LocalStorageHelper.LOCALE)
        data?.map((country: any) => {
          country.value = country.code
          country.label = locale === 'de' ? country.nameDE : country.nameEN
          return country
        })
        setCountryList(data)
      }
    } catch (error) {
      setCountryList([])
    }
  }

  /**
   * is check current plan is extra
   * @param currentPlan
   * @returns isExtraStatus
   */
  const isCheckExtraPlan = (currentPlan: string, accessiblityStatus?: any) => {
    const extraPlanTypes = [StoreHelper.PLAN_TYPES.ENTERPRISE, StoreHelper.PLAN_TYPES.EDUCATION]
    const isExtraStatus = extraPlanTypes.includes(currentPlan)
    if (!accessiblityStatus && !isNil(accessiblityStatus)) {
      return true
    } else {
      return isExtraStatus
    }
  }

  /**
   * create new contract pahse
   * @param contractInfo
   */
  const createNewContractPahse = async (contractInfo: any) => {
    setIsContentLoading(true)
    //free seat info
    const freeSeatCountInfo = contractInfo?.currentComponentSubscriptions?.find(
      (sub: any) => sub.freeSeats === true
    )
    //regular seat info
    const regularSeatCountInfo = contractInfo?.currentComponentSubscriptions?.find(
      (sub: any) => sub.freeSeats === false
    )
    const freeSeatCount = freeSeatCountInfo?.quantity ? freeSeatCountInfo?.quantity : 0
    const totalSeatCount =
      freeSeatCount + (regularSeatCountInfo?.quantity ? regularSeatCountInfo?.quantity : 0)

    const oldComponentsArray: any = []

    if (freeSeatCount === 0) {
      oldComponentsArray.push(regularSeatCountInfo?.componentId)
    } else {
      oldComponentsArray.push(regularSeatCountInfo?.componentId, freeSeatCountInfo?.componentId)
    }

    const futureComponentSubscriptionInfo = !isNil(futureComponentSubscription)
      ? futureComponentSubscription
      : !isNil(contractInfo?.futureComponentSubscription)
      ? contractInfo?.futureComponentSubscription
      : {}

    if (!isNil(futureComponentSubscriptionInfo) || !isEmpty(futureComponentSubscriptionInfo)) {
      size(futureComponentSubscriptionInfo) > 0 &&
        futureComponentSubscriptionInfo?.map((info: any) => {
          oldComponentsArray.push(info.componentId)
        })
    }

    const licenseInfo = {
      planVariantId: contractInfo?.contract?.currentPhase?.planVariantId,
      numberOfSeats:
        numberOfSeatCountInToken > totalSeatCount
          ? numberOfSeatCountInToken
          : regularSeatCountInfo?.quantity,
      numberOfFreeSeats: freeSeatCountInfo?.quantity ? freeSeatCountInfo?.quantity : null,
      contractId: contractInfo?.contract?.id,
      oldComponents: uniq(oldComponentsArray),
      componentId: regularSeatCountInfo?.componentId,
    }
    try {
      const contractResponse = await createNewContract(licenseInfo, userInfo?.id)
      if (!isNil(contractResponse)) {
        const orderResponse = await confirmOrder(
          contractResponse?.confirmationInfo?.orderId,
          userInfo?.id
        )
        setFutureComponentSubscription(orderResponse?.data?.futureComponentSubscription)
        setCustomBannerMessage(orderResponse?.data?.customerMessage)
      }
      setIsContentLoading(false)
    } catch (error) {
      setIsContentLoading(false)
    }
  }

  /**
   * unlock the contract
   * @param contractInfo
   */
  const unlockCurrentContract = async (contractInfo: any) => {
    try {
      const data = await unlockContract(userInfo?.id, contractInfo?.contract?.id)
      if (data?.status === 200) {
        fetchContractInfo(false, true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <PlanManagerContext.Provider
      value={{
        isPlanListLoading,
        selectablePlanList,
        managePlanInfoFormData,
        selectedManagePlanInfo,
        expandedSection,
        numberOfSeatCountInToken,
        isContractInfoLoading,
        isFormSubmitting,
        checkoutFormLicenseInfo,
        contractInfo,
        seatCountInfo,
        openOrderConfirmationModal,
        orderConfirmationInfo,
        selectedVatPercentage,
        countryList,
        paymentInfoUpdated,
        isPaymentInfoLoading,
        customBannerMessage,
        handleFormPlanItemChange,
        handleFormCancelClick,
        setExpandedSection,
        handleFormInputChange,
        handleCheckoutFormSubmit,
        setOpenOrderConfirmationModal,
        handleOrderConfirmationSubmit,
        isCheckExtraPlan,
      }}
    >
      {children}
    </PlanManagerContext.Provider>
  )
}

export default PlanManagerProvider
