export const RouteHelper = Object.freeze({
  BASE: {
    OVERVIEW: '/team/:teamId/overview',
    PLAN_MANAGER: '/team/:teamId/manage/:section',
    INVOICE_MANAGER: '/team/:teamId/invoices',
  },
  PATH: {
    OVERVIEW: '/team/{teamId}/overview',
    PLAN_MANAGER: '/team/{teamId}/manage',
    PLAN_MANAGER_LICENSE_SECTION: '/team/{teamId}/manage/plan',
    PLAN_MANAGER_PAYMENT_SECTION: '/team/{teamId}/manage/payment',
    PLAN_MANAGER_CUSTOMER_SECTION: '/team/{teamId}/manage/details',
    INVOICE_MANAGER: '/team/{teamId}/invoices',
  },
})
