import { FC, useContext } from 'react'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import { useTranslation } from 'react-i18next'
import size from 'lodash/size'

import { InvoiceManagerContext } from '../../../containers/invoiceManager/InvoiceManager.context'
import { StoreContext } from '../../../Store.context'
import Breadcrumb from '../../../../shared/components/breadcrumb/Breadcrumb'
import InvoiceTable from '../invoiceTable/InvoiceTable'
import InvoiceNoDataMessage from '../invoiceNoDataMessage/InvoiceNoDataMessage'
import { RouteHelper } from '../../../../../helpers/RouteHelper'
import {
  InvoiceTableLargeLoader,
  InvoiceTableMobileLoader,
} from '../../../../shared/components/contentLoader/invoiceManager/InvoiceTableLoader'
import { PageLoader } from '../../../../shared/components/contentLoader/page/PageLoader'
import { GeneralHelper } from '../../../../../helpers/GeneralHelper'
import { replaceParameters } from '../../../../../utility/CommonUtility'

const InvoiceManagerBase: FC = () => {
  const {
    isInoviceListLoading,
    invoiceList,
    invoicesActivePage,
    paginationData,
    isDownloadLoading,
    handlePaginationChange,
    handleInvoicesDownloadClick,
  } = useContext(InvoiceManagerContext)
  const { userInfo } = useContext(StoreContext)
  const { t } = useTranslation()

  const teamId = userInfo?.teamId
  const overviewPath = replaceParameters(RouteHelper.PATH.OVERVIEW, { teamId })
  const invoiceManagerBreadcrumb = [
    {
      id: 1,
      title: GeneralHelper.APP_TITLE,
      link: overviewPath,
      color: 'inherit',
      underline: 'hover',
    },
    {
      id: 2,
      title: t('invoiceManager.title'),
    },
  ]

  const loadingElement = () => {
    return (
      <div className="invoice-table__loading-content">
        <div className="desktop">
          <InvoiceTableLargeLoader />
        </div>
        <div className="mobile">
          <InvoiceTableMobileLoader />
        </div>
      </div>
    )
  }

  return (
    <Container className="inner-container">
      {isDownloadLoading && <PageLoader />}
      <div data-test="invoice-manager-base-component" className="invoice-manager mb_40">
        {/* breadcrumb */}
        <Grid container direction="row">
          <Grid item xs={12}>
            <Breadcrumb
              id={'invoice-manager-breadcrumb'}
              className={'invoice-manager-breadcrumb'}
              breadcrumbList={invoiceManagerBreadcrumb}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" className="mt_20 " spacing={2}>
          {isInoviceListLoading ? (
            <Grid item xs={12} sm={12} className={'mb_20'}>
              {loadingElement()}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12}>
              {size(invoiceList) > 0 ? (
                <InvoiceTable
                  id={'invoice-table'}
                  tableData={invoiceList}
                  activePage={invoicesActivePage}
                  handlePaginationChange={handlePaginationChange}
                  pageCount={paginationData?.pageCount}
                  handleInvoicesDownloadClick={handleInvoicesDownloadClick}
                  paginationLabels={{
                    nextButtonText: t('invoiceManager.paginationLabels.nextButtonText'),
                    previousButtonText: t('invoiceManager.paginationLabels.previousButtonText'),
                  }}
                />
              ) : (
                <InvoiceNoDataMessage
                  id={'invoice-no-data-message'}
                  message={t('invoiceManager.emptyStateMessage')}
                  description={t('invoiceManager.emptyStateDescription')}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </Container>
  )
}

export default InvoiceManagerBase
