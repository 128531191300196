import ContentLoader from 'react-content-loader'

export const DetailsSectionLoader = () => {
  const sectionItem = () => (
    <ContentLoader
      speed={2}
      width="100%"
      height="100"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="15" rx="30" ry="30" width="100%" height="90" />
    </ContentLoader>
  )
  return (
    <>
      {sectionItem()}
      {sectionItem()}
      {sectionItem()}
    </>
  )
}
