import ContentLoader from 'react-content-loader'

export const QuickLinksDesktopLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ float: 'right' }}
    >
      <rect x="0" y="15" rx="5" ry="20" width="20%" height="12" />
      <rect x="0" y="45" rx="5" ry="20" width="35%" height="12" />
      <rect x="0" y="75" rx="5" ry="20" width="30%" height="12" />
    </ContentLoader>
  )
}

export const QuickLinksMobileLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="15" rx="5" ry="20" width="20%" height="12" />
      <rect x="0" y="45" rx="5" ry="20" width="35%" height="12" />
      <rect x="0" y="75" rx="5" ry="20" width="30%" height="12" />
    </ContentLoader>
  )
}
