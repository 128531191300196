import { FC } from 'react'
import classnames from 'classnames'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Title } from 'ccs-react-lib'
import { useTranslation } from 'react-i18next'

import IInvoiceSummary from './InvoiceSummary.types'
import { getCurrencySymbol } from '../../../../../utility/CommonUtility'

import './InvoiceSummary.scss'

const InvoiceSummary: FC<IInvoiceSummary> = (props) => {
  const { id, className, discount, vatPercentage, summaryInfo, currency } = props
  const { t } = useTranslation()
  const subTotal =
    summaryInfo.billingPeriod && summaryInfo.price > 0 && summaryInfo.numberOfSeats > 0
      ? summaryInfo.price * Number(summaryInfo.billingPeriod) * summaryInfo.numberOfSeats
      : 0
  const vatValue = (subTotal > 0 ? ((subTotal - discount) * vatPercentage) / 100 : 0).toFixed(2)
  const total = Number(subTotal) - Number(discount) + Number(vatValue)

  /**
   * get the billing period text
   * @param billingPeriod
   * @returns billing period text
   */
  const getBillingPeriodText = (billingPeriod: number) => {
    switch (billingPeriod) {
      case 12:
        return t('planManager.summary.breakdown.annually')
      case 6:
        return t('planManager.summary.breakdown.semiAnnually')
      case 3:
        return t('planManager.summary.breakdown.quarterly')
      case 1:
        return t('planManager.summary.breakdown.monthly')
      default:
        return ''
    }
  }

  return (
    <div
      id={id}
      className={classnames('invioce-summary', className)}
      data-test="invioce-summary-component"
    >
      <Title
        id={'invioce-summary-title'}
        content={t('planManager.summary.title')}
        className="invioce-summary__title"
        weight="bold"
      />

      <Grid container direction="row" className="mt_10" data-test="invioce-subtotal">
        <Grid item xs={6} sm={6}>
          <Typography variant="body2" className="invioce-summary__heading">
            {t('planManager.summary.subtotal')}:
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} className="text--right">
          <Typography variant="body2">{`${getCurrencySymbol(currency || 'EUR')} ${subTotal.toFixed(
            2
          )}`}</Typography>
        </Grid>
      </Grid>
      {discount > 0 && (
        <Grid container direction="row" className="mt_10" data-test="invioce-discount">
          <Grid item xs={6} sm={6}>
            <Typography variant="body2" className="invioce-summary__heading">
              {t('planManager.summary.discount')}:
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} className="text--right">
            <Typography variant="body2">{`${getCurrencySymbol(
              currency || 'EUR'
            )} ${discount.toFixed(2)}`}</Typography>
          </Grid>
        </Grid>
      )}

      {Number(vatValue) > 0 && (
        <Grid container direction="row" className="mt_10" data-test="invioce-vat">
          <Grid item xs={6} sm={6}>
            <Typography variant="body2" className="invioce-summary__heading">
              {t('planManager.summary.vat')} ({vatPercentage}%):
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} className="text--right">
            <Typography variant="body2">
              {getCurrencySymbol(currency || 'EUR')} {vatValue}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        className="mt_10 pt_5 invioce-summary__total"
        data-test="invioce-summary-info"
      >
        <Grid item xs={3} sm={3}>
          <Typography variant="body2" className="invioce-summary__heading mt_10">
            {t('planManager.summary.total')}:
          </Typography>
        </Grid>
        <Grid item xs={9} sm={9} className="text--right invioce-summary__total-info-wrapper">
          <div className="invioce-summary__total-info">
            <Typography className="invioce-summary__total-amount">
              {`${getCurrencySymbol(currency || 'EUR')} ${total.toFixed(2)}`}
            </Typography>
            {summaryInfo.price > 0 && summaryInfo.numberOfSeats > 0 && summaryInfo.billingPeriod ? (
              <Typography
                variant="caption"
                className="invioce-summary__total-sub pr_10"
                data-test="invioce-extra-info"
              >
                {getCurrencySymbol(currency || 'EUR')} {summaryInfo?.price?.toFixed(2)} x{' '}
                {summaryInfo?.numberOfSeats} {t('planManager.summary.breakdown.users')} x{' '}
                {getBillingPeriodText(summaryInfo?.billingPeriod)}
              </Typography>
            ) : (
              ''
            )}
            {Number(vatValue) === 0 && (
              <Typography
                variant="caption"
                className="invioce-summary__total-sub pr_10"
                data-test="invioce-tax-info"
              >
                {t('planManager.summary.noTaxMessage')}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default InvoiceSummary
