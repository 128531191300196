import { FC } from 'react'
import classnames from 'classnames'
import Grid from '@mui/material/Grid'
import { Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import { ButtonGroupField, Label, InputTextField } from 'ccs-react-lib'

import PlanInfo from '../../overview/plan/planInfo/PlanInfo'
import ILicenseInfo from './LicenseInfo.types'
import { StoreHelper } from '../../../../../helpers/features/StoreHelper'
import { PlanListLoader } from '../../../../shared/components/contentLoader/planManager/planList/PlanListLoader'
import { InputElementLoader } from '../../../../shared/components/contentLoader/planManager/inputElement/InputElementLoader'
import { replaceParameters } from '../../../../../utility/CommonUtility'
import i18n from '../../../../../translations/i18n'

import './LicenseInfo.scss'

const LicenseInfo: FC<ILicenseInfo> = (props) => {
  const {
    id,
    className,
    formProps,
    handleFormPlanItemChange,
    planList,
    numberOfSeatCountInToken,
    isPlanListLoading,
    handleFormInputChange,
    isLoading,
    seatCountInfo,
    contractStatus,
    contractLockStatus,
  } = props
  const { t } = useTranslation()
  const billingPeriodList = [
    {
      value: '1',
      label: t('planManager.licenseConfiguration.billingPeriod.options.monthly'),
    },
    {
      value: '3',
      label: t('planManager.licenseConfiguration.billingPeriod.options.quarterly'),
    },
    {
      value: '6',
      label: t('planManager.licenseConfiguration.billingPeriod.options.semiAnnually'),
    },
    {
      value: '12',
      label: t('planManager.licenseConfiguration.billingPeriod.options.annually'),
    },
  ]

  const checkIsDisability = (plan: any, contractLockStatus: boolean) => {
    if (contractLockStatus) {
      return true
    }
    if (
      formProps.values.numberOfSeats < StoreHelper.MAX_SEAT_LIMIT &&
      plan.title === StoreHelper.PLAN_TYPES.BUSINESS
    ) {
      return true
    }
    return false
  }

  /**
   * create plan tile
   * @param plan
   * @returns
   */
  const createPlanTile = (plan: any) => {
    return (
      <PlanInfo
        id={plan.id}
        title={plan.title}
        price={plan.price}
        description={plan.description}
        isSmall
        callbackValue={plan.id}
        currency={plan.currency}
      />
    )
  }

  /**
   * handle plan item click
   * @param value
   */
  const handlePlanClick = (value?: any) => {
    const planInfo = planList?.find((info: any) => info?.id == value?.target?.value)
    !checkIsDisability(planInfo, contractLockStatus) &&
      handleFormPlanItemChange(formProps, [
        { name: 'planTitle', value: planInfo?.title },
        { name: 'planId', value: planInfo?.id },
      ])
  }

  /**
   * handle plan more info click
   */
  const handlePlanMoreInfoClick = () => {
    if (i18n.language == 'de') {
      window.open(StoreHelper.LEARN_MORE_LINK.de, '_blank')
    } else {
      window.open(StoreHelper.LEARN_MORE_LINK.en, '_blank')
    }
  }

  /**
   * render plan label description
   * @returns
   */
  const planLabelDescription = () => {
    return (
      <div className="plan-info__label-description">
        <p>
          <span className="label-wrapper__description">
            {t('planManager.licenseConfiguration.plan.description')}
          </span>
          <span
            id={'plan-more-info'}
            className="clickable quick-links__links"
            onClick={() => handlePlanMoreInfoClick()}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter') handlePlanMoreInfoClick()
            }}
          >
            {t('planManager.licenseConfiguration.plan.link')}
          </span>
        </p>
      </div>
    )
  }

  /**
   * render selectable plan items
   * @param contractLockStatus
   * @returns
   */
  const selectablePlanItems = (contractLockStatus: boolean) => (
    <>
      <ButtonGroupField
        labelText={t('planManager.licenseConfiguration.plan.label')}
        labelDescription={planLabelDescription()}
        labelVisibility={true}
        options={
          planList
            ? planList?.map((plan) => ({
                value: plan.id,
                content: createPlanTile(plan),
                disabled: checkIsDisability(plan, contractLockStatus),
              }))
            : []
        }
        value={formProps.values.planId}
        id="plan-list"
        className="plan-info__group"
        isRequired={true}
        fullWidth
        onChange={handlePlanClick}
      />
    </>
  )

  /**
   * get number of seat description
   * @returns
   */
  const getNumberOfSeatDescription = () => {
    return (
      (contractStatus === StoreHelper.CONTRACT_STATUS.TRIAL ||
        contractStatus === StoreHelper.CONTRACT_STATUS.ACTIVE) &&
      Number(seatCountInfo.freeSeat) > 0 &&
      replaceParameters(t('planManager.licenseConfiguration.numberOfSeat.freeSeatlabel'), {
        freeSeatCount: Number(seatCountInfo.freeSeat),
      })
    )
  }

  /**
   * check is number
   * @param e
   * @returns
   */
  const checkIsNumber = (e: any) => {
    const numbers = /^[0-9]+$/
    if (e.target.value.match(numbers) || e.target.value === '') {
      return true
    } else {
      return false
    }
  }

  return (
    <div
      id={id}
      className={classnames('license-info', className)}
      data-test="license-info-component"
    >
      <Grid container rowSpacing={2.5} data-test="plan-info">
        <Grid item xs={12} md={12} data-test="note">
          <p className="license-info__note">
            {t('planManager.licenseConfiguration.requiredNote')}
            <span className="license-info__required">*</span>
          </p>
        </Grid>
        <Grid item xs={12} sm={12} className="row-item">
          {isPlanListLoading ? (
            <div className="license-info__plan-loading">
              <PlanListLoader />
            </div>
          ) : (
            selectablePlanItems(contractLockStatus)
          )}
          <Field id="plan-id" type="hidden" name="planId" value={formProps.values.planId} />
          <Field
            id="plan-title"
            type="hidden"
            name="planTitle"
            value={formProps.values.planTitle}
          />
          <ErrorMessage name={'planId'} component="div" className="formik-error-label" />
        </Grid>

        <Grid item xs={12} sm={12} className="row-item" data-test="billing-period">
          {isLoading ? (
            <InputElementLoader />
          ) : (
            <>
              <ButtonGroupField
                labelText={t('planManager.licenseConfiguration.billingPeriod.label')}
                labelVisibility={true}
                options={
                  billingPeriodList
                    ? billingPeriodList?.map((period) => ({
                        value: period.value,
                        content: period.label,
                        disabled: contractLockStatus,
                      }))
                    : []
                }
                value={String(formProps.values.billingPeriod)}
                id="billing-period"
                className={classnames('billing-period__group', {
                  'billing-period__group--disabled': contractLockStatus,
                })}
                isRequired={true}
                fullWidth
                onChange={(e: any) => {
                  handleFormInputChange(formProps, { name: 'billingPeriod', value: e.target.value })
                }}
                isError={formProps.errors.billingPeriod && formProps.touched.billingPeriod}
                errorMessage={formProps.errors.billingPeriod}
              />
            </>
          )}
        </Grid>

        <Grid item xs={12} md={12} data-test="number-of-seats">
          <Grid container direction="row">
            <Grid item xs={12} sm={12} className="row-item">
              {isLoading ? (
                <InputElementLoader />
              ) : (
                <InputTextField
                  id="number-of-seat"
                  inputName="numberOfSeats"
                  placeholderText={t('planManager.licenseConfiguration.numberOfSeat.label')}
                  autoComplete="off"
                  onChange={(e: any) => {
                    if (checkIsNumber(e)) {
                      handleFormInputChange(formProps, {
                        name: 'numberOfSeats',
                        value: e.target.value,
                      })
                    }
                  }}
                  onBlur={formProps.handleBlur}
                  inputType="text"
                  labelText={t('planManager.licenseConfiguration.numberOfSeat.label')}
                  labelVisibility={true}
                  value={formProps.values.numberOfSeats}
                  className={classnames('number-of-seat', {
                    'label__number-of-seat': Number(seatCountInfo.freeSeat) > 0,
                  })}
                  isError={formProps.errors.numberOfSeats && formProps.touched.numberOfSeats}
                  errorMessage={formProps.errors.numberOfSeats}
                  isRequired
                  data-test="number-of-seats-input"
                  isDisabled={contractLockStatus}
                  labelDescription={getNumberOfSeatDescription() || null}
                />
              )}
              <Field
                id="token-seat-count"
                type="hidden"
                name="tokenSeatCount"
                value={numberOfSeatCountInToken}
              />
              <Field
                id="free-seat-count"
                type="hidden"
                name="freeSeatCount"
                value={seatCountInfo.freeSeat}
              />
              <Field
                id="billing-period-value"
                type="hidden"
                name="billingPeriodValue"
                value={formProps.values.billingPeriod}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default LicenseInfo
