import { LocalStorageHelper } from '../helpers/LocalStorageHelper'
import { StoreHelper } from '../helpers/features/StoreHelper'
import StorageUtitlity from '../utility/StorageUtility'

/**
 * @function getCurrencySymbol
 * @param {*} currencyName
 * @returns {currencySymbol}
 */
export const getCurrencySymbol = (currencyName: string) => {
  let currencySymbol = '€'
  if (currencyName === 'USD') {
    currencySymbol = '$'
  } else if (currencyName === 'EUR') {
    currencySymbol = '€'
  }
  return currencySymbol
}

/**
 * @function getMetaValueByTagName
 * @param name
 * @returns
 */
export const getMetaValueByTagName = (name: string) => {
  const metaValue = document.getElementsByTagName('meta').namedItem(name)?.getAttribute('content')
  if (metaValue === '${' + name + '}') {
    return null
  }
  return metaValue ?? null
}

/**
 * @function checkActionAvailability
 * @param env
 * @returns
 */
export const checkActionAvailability = (env?: any) => {
  if (!!env && (env === 'local' || env === 'development')) {
    return true
  } else {
    return true // enabled this action for the prod users MTA-145
  }
}

/**
 * @function checkDateIsPast
 * @param date
 * @returns
 */
export const checkDateIsPast = (date?: any) => {
  const oldDate = new Date(date)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return oldDate < today
}

/**
 * decodeJwt
 * @param token
 * @returns token data obj
 */
export const decodeJwt = (token: any) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

/**
 * replace the parameters in the string
 * @param value
 * @param parameters
 * @returns formatted value
 */
export const replaceParameters = (value: string, parameters: any) => {
  for (const param of Object.keys(parameters)) {
    value = value.replace('{'.concat(param).concat('}'), parameters[param])
  }

  return value
}

/**
 * get the return url
 * @returns
 */
export const getReturnUrl = () => {
  const returnUrl = StorageUtitlity.getSingleData(LocalStorageHelper.RETURN_URL)
    ? StorageUtitlity.getSingleData(LocalStorageHelper.RETURN_URL)
    : 'https://app.conceptboard.com'
  return returnUrl
}

/**
 * get current environment
 * @returns
 */
export const getCurrentEnvironment = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || getMetaValueByTagName('REACT_APP_ENVIRONMENT')
  return env
}

/**
 * get team team id
 * @returns teamId
 */
export const getTeamId = () => {
  const teamId = window.location.pathname.split('/')[2]
  return teamId
}

/**
 * escape html tags in the string
 * @param string
 * @returns plainString
 */
export const escapeHtmlTags = (string: any) => {
  const regex = /(<([^>]+)>)/gi
  const plainString = string.replace(regex, ' ')
  return plainString
}

/**
 * get message string
 * @param string
 * @returns message
 */

export const getMessageString = (string: any) => {
  let message
  if (string.includes(':')) {
    message = string.replace(/\{|\}/gi, '').split(':')[2].replace(/['"]/g, '')
  } else {
    message = string
  }
  return message
}

/**
 * check checkout section accessibility
 * @param status
 * @param planName
 * @returns
 */
export const checkCheckoutSectionAccessibility = (status?: any, planName?: any) => {
  const PlanTypes = [StoreHelper.PLAN_TYPES.PREMIUM, StoreHelper.PLAN_TYPES.BUSINESS]
  if (planName && PlanTypes.includes(planName) && status === 'false') {
    return false
  }
  return true
}
