import { FC } from 'react'

import OverviewBase from '../../components/overview/overviewBase/OverviewBase'
import StoreProvider from '../../Store.context'
import OverviewProvider from './Overview.context'

const Overview: FC = () => {
  return (
    <StoreProvider>
      <OverviewProvider>
        <OverviewBase data-test="overview-wrapper" />
      </OverviewProvider>
    </StoreProvider>
  )
}

export default Overview
