import { FC } from 'react'

import InvoiceManagerBase from '../../components/invoiceManager/invoiceManagerBase/InvoiceManagerBase'
import InvoiceManagerProvider from './InvoiceManager.context'

const InvoiceManager: FC = () => {
  return (
    <InvoiceManagerProvider>
      <InvoiceManagerBase data-test="invoice-manager-wrapper" />
    </InvoiceManagerProvider>
  )
}

export default InvoiceManager
