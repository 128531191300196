import { toast } from 'react-toastify'

/**
 * show alert message
 * @param alerType
 * @param message
 */
export const showAlert = (alerType: AlertType, message: string) => {
  toast[alerType](message, {
    autoClose: 1000,
  })
}

type AlertType = 'info' | 'success' | 'warning' | 'error'
