import store from 'store2'

/**
 * setAllData
 * @param storeObject
 */
const setAllData = (storeObject: any) => {
  store.setAll(storeObject)
}

/**
 * setSingleData
 * @param key
 * @param value
 */
const setSingleData = (key: any, value: any) => {
  store.set(key, value)
}

/**
 * getAllData
 * @returns all
 */
const getAllData = () => {
  return store.getAll()
}

/**
 * getSingleData
 * @param props
 * @returns single item
 */
const getSingleData = (props: any) => {
  return store.get(props)
}

/**
 * clearData
 */
const clearData = () => {
  store.clear()
}

/**
 * hasData
 * @param key
 * @returns has data
 */
const hasData = (key: any) => {
  return store.has(key)
}

/**
 * removes key and its data, then returns the data or alt, if none
 * @param key
 */
const removeSingleData = (key: any) => {
  store.remove(key)
}

/**
 * concats, merges, or adds new value into existing one
 * @param key
 * @param value
 */
const addNewValue = (key: any, value: any) => {
  store.add(key, value)
}

/**
 * number of keys, not length of data
 */
const numberOfKeys = () => {
  store.size()
}

/**
 * clears *ALL* areas (but still namespace sensitive)
 */
const clearAllData = () => {
  store.clearAll()
}

const StorageUtitlity = {
  setAllData,
  setSingleData,
  getAllData,
  getSingleData,
  clearData,
  hasData,
  removeSingleData,
  addNewValue,
  numberOfKeys,
  clearAllData,
}

export default StorageUtitlity
