import ContentLoader from 'react-content-loader'

export const SummaryDesktopLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="70"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="50%" y="15" rx="5" ry="20" width="50%" height="12" />
      <rect x="60%" y="45" rx="5" ry="20" width="40%" height="12" />
    </ContentLoader>
  )
}

export const SummaryMobileLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="70"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="5" ry="20" width="50%" height="12" />
      <rect x="0" y="30" rx="5" ry="20" width="40%" height="12" />
    </ContentLoader>
  )
}
