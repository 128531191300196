import { FC } from 'react'
import classnames from 'classnames'
import Typography from '@mui/material/Typography'

import IUserInfo from './UserInfo.types'

import './UserInfo.scss'

const UserInfo: FC<IUserInfo> = (props) => {
  const { id, className, name, email, variant, align } = props
  return (
    <span id={id} className={classnames('user-info', className)} data-test="user-info-component">
      <Typography variant={variant} align={align}>
        <span
          data-test="user-name"
          dangerouslySetInnerHTML={{
            __html: name,
          }}
        />{' '}
        &nbsp;
        {email && <span data-test="user-email">({email})</span>}
      </Typography>
    </span>
  )
}

export default UserInfo
