import ContentLoader from 'react-content-loader'

export const InvoiceTableLargeLoader = () => {
  const TableRows = () => {
    const allrows = []
    for (let i = 1; i <= 10; i++) {
      allrows.push(
        <ContentLoader
          speed={2}
          width={'100%'}
          height={50}
          viewBox="0 0 1050 50"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          key={i}
        >
          <rect x="0" y="15" rx="5" ry="5" width="80" height="12" />
          <rect x="280" y="15" rx="5" ry="5" width="150" height="12" />
          <rect x="550" y="15" rx="5" ry="5" width="80" height="12" />
          <rect x="770" y="15" rx="5" ry="5" width="100" height="12" />
          <rect x="0" y="48" rx="0" ry="0" width="1500" height="1" />
        </ContentLoader>
      )
    }
    return allrows
  }

  return <>{TableRows()}</>
}

export const InvoiceTableMobileLoader = () => {
  const TableRows = () => {
    const allrows = []
    for (let i = 1; i <= 5; i++) {
      allrows.push(
        <ContentLoader
          speed={2}
          width={'100%'}
          height={100}
          viewBox="0 0 550 150"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          key={i}
        >
          <rect x="0" y="0" rx="5" ry="5" width="80" height="15" />
          <rect x="0" y="30" rx="5" ry="5" width="150" height="15" />
          <rect x="0" y="60" rx="5" ry="5" width="60" height="15" />
          <rect x="440" y="0" rx="5" ry="5" width="100" height="35" />
          <rect x="0" y="115" rx="0" ry="0" width="800" height="1" />
        </ContentLoader>
      )
    }
    return allrows
  }

  return <>{TableRows()}</>
}
