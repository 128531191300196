import { createContext, useState, useEffect } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import isNil from 'lodash/isNil'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'

import { IStoreContext } from './Store.types'
import { IUser } from '../layout/components/header/Header.types'
import { showAlert } from '../../utility/ToasterUtility'
import i18n from '../../translations/i18n'
import { checkTokenValidity, getSelfServiceTokenByContract } from '../../api/store/StoreApi'
import { LocalStorageHelper } from '../../helpers/LocalStorageHelper'
import StorageUtitlity from '../../utility/StorageUtility'
import {
  decodeJwt,
  getCurrentEnvironment,
  getMetaValueByTagName,
} from '../../utility/CommonUtility'
import { GeneralHelper } from '../../helpers/GeneralHelper'
import {
  MotomoEventAction,
  MotomoEventCategory,
  MotomoMethod,
  setMatomoAnalytics,
} from '../../utility/MatomoAnalytics'

declare let SubscriptionJS: any
declare let _paq: any

export const StoreContext = createContext<IStoreContext>({} as IStoreContext)

const emptyUserInfo = {
  id: '',
  name: '',
  email: '',
  company: '',
  teamId: '',
}

const StoreProvider = (props: { children: any }) => {
  const { children } = props
  const navigate = useNavigate()
  const { hash } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [userInfo, setUserInfo] = useState<IUser>(emptyUserInfo)
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [paymentModalErrorMessage, setPaymentModalErrorMessage] = useState('')
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [contractId, setContractId] = useState('')
  const [updatePaymentInfoSuccess, setUpdatePaymentInfoSuccess] = useState({})
  const [isContentLoading, setIsContentLoading] = useState(false)
  const [isCheckoutSectionAccessibility, setIsCheckoutSectionAccessibility] = useState(false)
  const locale = searchParams.get('locale')
  const urlHashValues = hash?.split('=')
  const webAppToken = size(urlHashValues) > 1 ? urlHashValues[1] : ''

  useEffect(() => {
    const storeUserInfo = StorageUtitlity.getSingleData(LocalStorageHelper.STORE_USER_INFO)
    // set user info
    if (!isNil(storeUserInfo)) {
      setUserInfo(storeUserInfo)
    }
    // set locale info
    if (locale) {
      i18n.changeLanguage(locale)
    }

    if (webAppToken) {
      StorageUtitlity.clearData()
      checkTokenValidityStatus(webAppToken)
    } else {
      setIsPageLoading(false)
      const tokenInfo = StorageUtitlity.getSingleData(LocalStorageHelper.ACCESS_TOKEN)
      if (isNil(tokenInfo)) {
        navigate(`/error`)
      }
    }
  }, [])

  /**
   * @param data
   * handle the error call back from the billwerk form
   */
  const paymentFormErrorCallback = (data: any) => {
    setPaymentModalErrorMessage(data.errorMessage)
    setIsContentLoading(false)
  }

  /**
   * @param data
   * handle the success call back from the billwerk form
   */
  const paymentFormSuccessCallback = (data: any) => {
    setUpdatePaymentInfoSuccess(data)
    const matomoLogObj = {
      type: MotomoMethod.TRACK_EVENT,
      category: MotomoEventCategory.USER_ENGAGEMENT,
      action: MotomoEventAction.UPDATE_PAYMENT_INFO,
      value: JSON?.stringify({ 'team-id': userInfo?.id }),
    }
    setMatomoAnalytics(matomoLogObj)
    showAlert('success', t('paymentModal.successMessage'))
    setOpenPaymentModal(false)
  }

  /**
   * @param paymentForm
   * handle payment form submit
   */
  const handlePaymentFormSubmit = async (paymentForm: any) => {
    setIsContentLoading(true)
    setPaymentModalErrorMessage('')
    const selfServiceResponse = await getSelfServiceTokenByContract(contractId, userInfo?.id)
    const selfServiceToken = selfServiceResponse?.token
    if (selfServiceToken) {
      const portal = new SubscriptionJS.Portal(selfServiceResponse?.token)
      portal.paymentChange(null, paymentForm, paymentFormSuccessCallback, paymentFormErrorCallback)
    }
  }

  /**
   * check the token validity status from BW
   * @param token
   */
  const checkTokenValidityStatus = async (token: string) => {
    const tokenInfo = decodeJwt(token)
    const userObj = {
      id: tokenInfo['https://conceptboard.com/ns/oauth#claim_globalcompanyid'],
      name: tokenInfo?.name,
      email: tokenInfo?.email,
      company: tokenInfo['https://conceptboard.com/ns/oauth#claim_companyname'],
      teamId: window.location.pathname.split('/')[2],
    }
    const storeObj = {
      [LocalStorageHelper.ACCESS_TOKEN]: token,
      [LocalStorageHelper.RETURN_URL]:
        tokenInfo['https://conceptboard.com/ns/oauth#claim_returnurl'],
      [LocalStorageHelper.STORE_USER_INFO]: userObj,
    }
    StorageUtitlity.setAllData(storeObj)
    i18n.changeLanguage(tokenInfo?.locale === 'en-US' ? 'en' : 'de')
    setUserInfo(userObj)
    try {
      const responseStatus = await checkTokenValidity(token)
      if (responseStatus !== 200) {
        navigate(`/error?status=${responseStatus}`)
        setIsPageLoading(false)
      } else {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        axios.defaults.headers.common['Accept-Language'] =
          tokenInfo?.locale === 'en-US' ? 'en' : 'de'
        searchParams.delete('token')
        setSearchParams(searchParams)
        setIsPageLoading(false)
      }
    } catch (error) {
      setIsPageLoading(false)
    }
  }

  return (
    <StoreContext.Provider
      value={{
        userInfo,
        paymentModalErrorMessage,
        openPaymentModal,
        isPageLoading,
        isContentLoading,
        updatePaymentInfoSuccess,
        isCheckoutSectionAccessibility,
        handlePaymentFormSubmit,
        paymentFormErrorCallback,
        paymentFormSuccessCallback,
        setPaymentModalErrorMessage,
        setOpenPaymentModal,
        setContractId,
        setUpdatePaymentInfoSuccess,
        setIsContentLoading,
        setIsCheckoutSectionAccessibility,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export default StoreProvider
