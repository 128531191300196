import { FC, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { ToastContainer } from 'react-toastify'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'

import Header from '../../components/header/Header'
import Routers from '../../../../routers/Routers'
import { PageLoader } from '../../../shared/components/contentLoader/page/PageLoader'
import { StoreContext } from '../../../store/Store.context'
import IMain from './Main.types'
import { GeneralHelper } from '../../../../helpers/GeneralHelper'

const Main: FC<IMain> = (props) => {
  const { className } = props
  const { userInfo, isPageLoading, isContentLoading } = useContext(StoreContext)
  const location = useLocation().pathname
  const billwerSelfServiceUrl = GeneralHelper.BILLWERK_SELFSERVICE_URL

  return (
    <div data-test="main-layout" className={classnames('main-layout', className)} role="main">
      <Helmet>
        <script src={billwerSelfServiceUrl} async></script>
      </Helmet>
      <Grid container>
        {isPageLoading ? (
          <PageLoader />
        ) : (
          <>
            {isContentLoading && <PageLoader />}
            <Grid item xs={12}>
              {location !== '/' && <Header id={'header'} userInfo={userInfo} />}
            </Grid>
            <Routers />
            <ToastContainer theme="dark" position="top-left" />
          </>
        )}
      </Grid>
    </div>
  )
}

export default Main
