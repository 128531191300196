import ContentLoader from 'react-content-loader'
import Grid from '@mui/material/Grid'

export const PlanListLoader = () => {
  const planItem = () => (
    <ContentLoader
      speed={2}
      width="100%"
      height="320"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="30" ry="30" width="100%" height="320" />
    </ContentLoader>
  )
  return (
    <>
      <Grid item xs={12} sm={4}>
        {planItem()}
      </Grid>
      <Grid item xs={12} sm={4}>
        {planItem()}
      </Grid>
      <Grid item xs={12} sm={4}>
        {planItem()}
      </Grid>
    </>
  )
}
