import { AxiosRequestConfig } from 'axios'

import * as ApiHelper from '../helpers/ApiHelper'
import { getMessageString } from '../utility/CommonUtility'
import { showAlert } from '../utility/ToasterUtility'

const request = (requestToExecute: Promise<any>) => {
  //TODO: handle the request
  return requestToExecute.catch((err) => {
    handleRequestFailure(err)
  })
}

const handleUnauthorized = (errorCode: any) => {
  const errorPage = `${window.location.origin}/error?status=${errorCode}`
  window.location.replace(errorPage)
}

const handleRequestFailure = (error: any) => {
  const { response } = error
  if (response && response.status === 401) {
    // console.log('unauthorized')
    handleUnauthorized(response.status)
    throw response.status
  } else if (response && response.data) {
    if (response.data?.message) {
      const message = getMessageString(response.data.message)
      showAlert('error', message)
    }
    return response.data
  } else {
    if (response === undefined) {
      // TODO: handle the unauthorized
    }
    throw new Error(error)
  }
}

export const get = (url: string, config = {}) => request(ApiHelper.get(url, config))

export const post = (url: string, body: any, config: AxiosRequestConfig<any> | undefined) =>
  request(ApiHelper.post(url, body, config))

export const put = (url: string, body: any, config: AxiosRequestConfig<any> | undefined) =>
  request(ApiHelper.put(url, body, config))

export const patch = (url: string, body: any, config: AxiosRequestConfig<any> | undefined) =>
  request(ApiHelper.patch(url, body, config))

export const del = (url: string, body: any, config: AxiosRequestConfig<any> | undefined) =>
  request(ApiHelper.del(url, body, config))

export const putWithoutAuth = (
  url: string,
  body: any,
  config: AxiosRequestConfig<any> | undefined
) => request(ApiHelper.putWithoutAuth(url, body, config))
