import { FC } from 'react'
import classnames from 'classnames'

import ICustomMessage from './CustomMessage.types'

import './CustomMessage.scss'

const CustomMessage: FC<ICustomMessage> = (props) => {
  const { id, className, message } = props
  return (
    <div
      id={id}
      className={classnames('custom-message', className)}
      data-test="custom-message-component"
    >
      {message}
    </div>
  )
}

export default CustomMessage
