import { createContext, useState, useEffect, useContext } from 'react'
import { saveAs } from 'file-saver'
import { t } from 'i18next'

import { StoreHelper } from '../../../../helpers/features/StoreHelper'
import { IInvoiceManagerContext, IInvoiceParams } from './InvoiceManager.types'
import { IInvoice } from '../../components/invoiceManager/invoiceTable/InvoiceTable.types'
import { getInvoicesList, getDownloadableInvoiceById } from '../../../../api/store/StoreApi'
import { StoreContext } from '../../Store.context'
import { MotomoMethod, setMatomoAnalytics } from '../../../../utility/MatomoAnalytics'

export const InvoiceManagerContext = createContext<IInvoiceManagerContext>(
  {} as IInvoiceManagerContext
)

const InvoiceManagerProvider = (props: { children: any }) => {
  const { children } = props

  const [isInoviceListLoading, setIsInoviceListLoading] = useState(false)
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const [invoiceList, setInvoiceList] = useState<IInvoice[]>([])
  const [invoicesActivePage, setInvoicesActivePage] = useState(1)
  const [paginationData, setPaginationData] = useState({})
  const { userInfo } = useContext(StoreContext)

  const defaultParams = {
    page: 0,
    size: StoreHelper.INVOICE_LIST_PAGE_LIMIT,
  }

  useEffect(() => {
    setIsInoviceListLoading(true)
    fetchInvoicesList(defaultParams)
    const pageInfo = {
      type: MotomoMethod.TRACK_PAGE_VIEW,
      documentTitle: t('matomoLog.pageTitle.invoiceSection'),
      href: window.location.href,
    }
    setMatomoAnalytics(pageInfo)
  }, [])

  /**
   *fetch invoices list
   * @param params page and size
   */
  const fetchInvoicesList = async (params: IInvoiceParams) => {
    try {
      const { content, totalPages } = await getInvoicesList(params, userInfo?.id)
      setInvoiceList(content)
      setIsInoviceListLoading(false)
      setPaginationData({ ...paginationData, pageCount: totalPages })
    } catch (error) {
      setInvoiceList([])
      setIsInoviceListLoading(false)
    }
  }

  /**
   * handle invoices list pagination click
   * @param event
   * @param page
   */
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setInvoicesActivePage(page)
    setIsInoviceListLoading(true)
    const currentPage = page - 1
    const params = { ...defaultParams, page: currentPage }
    fetchInvoicesList(params)
  }
  /**
   * handle invoices download action
   * @param invoiceId
   */
  const handleInvoicesDownloadClick = async (invoiceData: any) => {
    setIsDownloadLoading(true)
    const fileInfo = await getDownloadableInvoiceById(invoiceData.id, userInfo?.id)
    saveAs(fileInfo, `${invoiceData.name}.pdf`)
    setIsDownloadLoading(false)
  }

  return (
    <InvoiceManagerContext.Provider
      value={{
        isInoviceListLoading,
        invoiceList,
        invoicesActivePage,
        paginationData,
        isDownloadLoading,
        handlePaginationChange,
        handleInvoicesDownloadClick,
      }}
    >
      {children}
    </InvoiceManagerContext.Provider>
  )
}

export default InvoiceManagerProvider
