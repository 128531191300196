import { FC } from 'react'
import classnames from 'classnames'
import { Title } from 'ccs-react-lib'

import ISectionTitle from './SectionTitle.types'

import './SectionTitle.scss'

const SectionTitle: FC<ISectionTitle> = (props) => {
  const { id, className, variant, content } = props
  return (
    <div
      id={id}
      className={classnames('section-title mb_10', className)}
      data-test="section-title-component"
    >
      <Title id={'title'} variant={variant} content={content} />
    </div>
  )
}

export default SectionTitle
