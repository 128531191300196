import { FC, useCallback } from 'react'
import classnames from 'classnames'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Card, Button } from 'ccs-react-lib'
import { useTranslation } from 'react-i18next'
import isNumber from 'lodash/isNumber'

import IPlanInfo from './PlanInfo.types'
import { getCurrencySymbol } from '../../../../../../utility/CommonUtility'

import './PlanInfo.scss'

const PlanInfo: FC<IPlanInfo> = (props) => {
  const {
    id,
    className,
    isActive,
    title,
    price,
    description,
    subDescription,
    buttonText,
    onClick,
    callbackValue,
    isSmall,
    currencyVisibility,
    currency,
    activeLabel,
  } = props

  const { t } = useTranslation()

  const activeClass = isSmall
    ? `plan-info--small ${isActive && 'plan-info--small-active'}`
    : `plan-info--large ${isActive && 'plan-info--large-active'}`

  const handleClick = useCallback(
    (e) => {
      const event = e
      onClick?.(callbackValue, event)
    },
    [onClick, callbackValue]
  )

  const largeCard = () => (
    <>
      <div data-test="large-plan-card">
        <div className="plan-info__active-bar">
          {isActive && (
            <Typography variant="body1" align="center" className="pt_5 pb_5" data-test="active-bar">
              {activeLabel}
            </Typography>
          )}
        </div>
        <div className="plan-info__content pl_40 pr_40">
          <Typography
            variant="h2"
            align="center"
            className="plan-info__title mt_10"
            data-test="plan-title"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Typography
            variant={price != '' ? 'h3' : 'body1'}
            align="center"
            className="plan-info__price mt_5 mb_10"
            data-test="plan-price"
          >
            {price != '' && (
              <>
                {currencyVisibility && `${getCurrencySymbol(currency || 'EUR')}`}
                {`${price}`}
              </>
            )}
          </Typography>
          {description && (
            <Typography
              variant="body2"
              align="center"
              className="plan-info__description mb_10"
              data-test="plan-description"
              dangerouslySetInnerHTML={{
                __html: `${description}`,
              }}
            />
          )}
          {subDescription && (
            <Typography
              variant="body2"
              align="center"
              className="plan-info__sub-description mb_20"
              data-test="plan-sub-description"
              dangerouslySetInnerHTML={{
                __html: `${subDescription}`,
              }}
            />
          )}
        </div>
      </div>
      <div>
        {buttonText && (
          <div className="mb_30">
            <Button
              id={'plan-info-btn'}
              className="store-btn plan-info__btn"
              onClick={onClick}
              callbackValue={callbackValue}
              data-test="plan-action-button"
              tabIndex={0}
              content={buttonText}
              variant={isActive ? 'primary' : 'tertiary'}
              whiteBackground={isActive ? false : true}
            />
          </div>
        )}
      </div>
    </>
  )

  const smallCard = () => (
    <div className="small-plan-wrapper" data-test="small-plan-card">
      <Grid container direction="row" onClick={handleClick}>
        <Grid item className="plan-info__title-wrapper">
          <Typography
            id="plan-info-title"
            variant="h5"
            align="center"
            className="plan-info__title"
            data-test="plan-title"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </Grid>
        <Grid item className="plan-info__price-wrapper">
          <Typography
            id="plan-info-price"
            variant="h5"
            align="center"
            className="plan-info__price"
            data-test="plan-price"
          >
            {isNumber(price) && `${getCurrencySymbol(currency || 'EUR')}${price.toFixed(2)}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          {description && (
            <Typography
              id="plan-info-description"
              variant="body2"
              align="center"
              className="plan-info__description"
              data-test="plan-description"
            >
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  )

  return (
    <Card
      id={id}
      className={classnames('plan-info', activeClass, className)}
      data-test="plan-info-component"
    >
      {isSmall ? smallCard() : largeCard()}
    </Card>
  )
}

export default PlanInfo
