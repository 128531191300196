import axios, { AxiosRequestConfig } from 'axios'

import { LocalStorageHelper } from '../helpers/LocalStorageHelper'
import StorageUtitlity from '../utility/StorageUtility'

const token =
  !!process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? process.env.REACT_APP_API_TOKEN
    : StorageUtitlity.getSingleData(LocalStorageHelper.ACCESS_TOKEN)

axios.defaults.headers.common.Authorization = `Bearer ${token}`
axios.defaults.headers.common['Accept-Language'] = StorageUtitlity.getSingleData(
  LocalStorageHelper.LOCALE
)
axios.interceptors.request.use(
  (request) => {
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * get method
 * @param {*} url
 * @param {*} config
 */
export const get = (url: string, config: AxiosRequestConfig<any> | undefined) =>
  axios.get(url, config)

/**
 * post method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const post = (url: string, body: any, config: AxiosRequestConfig<any> | undefined) =>
  axios.post(url, body, config)

/**
 * put method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const put = (url: string, body: any, config: AxiosRequestConfig<any> | undefined) =>
  axios.put(url, body, config)

/**
 * patch method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const patch = (url: string, body: any, config: AxiosRequestConfig<any> | undefined) =>
  axios.patch(url, body, config)

/**
 * delete method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const del = (url: string, body: any, config: AxiosRequestConfig<any> | undefined) =>
  axios.delete(url, config)

/**
 * put method without Autherization header
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
const instance = axios.create()
export const putWithoutAuth = (
  url: string,
  body: any,
  config: AxiosRequestConfig<any> | undefined
) => instance.put(url, body, config)
